import {
	NOTIFICATIONS_SET, 
	NOTIFICATIONS_LOCK, 
	NOTIFICATIONS_UNLOCK, 
} from 'constants';

const initial = {
	list: [],
	locked: false,
};

export default (state = initial, action) => {
	switch (action.type) {
		case NOTIFICATIONS_SET:
			return { ...state, list: action.payload };
		case NOTIFICATIONS_LOCK:
			return { ...state, locked: true };
		case NOTIFICATIONS_UNLOCK:
			return { ...state, locked: false };
		default:
			return state;
	}
};
