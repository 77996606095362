import fetcher from 'helpers/fetcher';

export default {
	getUnread () {
		return fetcher.get('private/push-notification/UNREAD');
	},
	getAll () {
		return fetcher.get('private/push-notification/ALL');
	},
	markAsRead (ids) {
		fetcher.post('private/push-notification', { ids });
	},
	alreadyCard (id) {
		return fetcher.post(`private/push-notification/tiande/order-card/${id}`)
	},
	cancelOrderingCard (id) {
		return fetcher.post(`private/push-notification/tiande/not-order-card/${id}`)
	}
};
