import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl'
import * as jumioActions from 'actions/jumio';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { JUMIO_VERIFICATIONS__STATUS } from 'constants';
import BlockingScreen from '../../Blocking';

import M from './JumioVerification.locale.json';

const VerificationStatusText = {
  [JUMIO_VERIFICATIONS__STATUS.IN_PROCESS]: M.inprogress,
  [JUMIO_VERIFICATIONS__STATUS.SUCCESS]: M.success,
  [JUMIO_VERIFICATIONS__STATUS.FAILED]: M.failed
};

const CARD_PRODUCT_NOT_VERIFICATION = ['CARD_04', 'CARD_05', 'CARD_06'];

const JumioVerification = ({ actions, cards, status, link, intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!cards.items.length) return null;

  if (!cards.items.some(card => CARD_PRODUCT_NOT_VERIFICATION.includes(card.product))) {
    return (
      <div className="SettingScreen__Document">
        {
          !isLoading &&
          <BlockingScreen />
        }
      </div>);
  }

  useEffect(() => {
    actions.jumio.getVerificationStatus(cards.items[0].cardholderId);
  }, []);

  const t = intl.formatMessage;
  const textStatus = status && VerificationStatusText[status];
  const verificatioIsSuccess = status === JUMIO_VERIFICATIONS__STATUS.SUCCESS;
  const verificatioIsFailed = status === JUMIO_VERIFICATIONS__STATUS.FAILED;
  const verificatioIsProgress = status === JUMIO_VERIFICATIONS__STATUS.IN_PROCESS;
  const isDisabledButton = verificatioIsProgress || verificatioIsSuccess || !link;

  useEffect (() => {
    if (!link && cards.current) {
      actions.jumio.getLinkVerification(cards.current);
    }
  },[link, cards.current]);

  const handleClick = useCallback(async () => {
    if (verificatioIsProgress || !link) return;
    setIsLoading(true);
    try {
      await actions.jumio.startVerification(cards.items[0].cardholderId);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }, [link, cards.items[0], verificatioIsProgress, cards.current]);

  return (
    <div className='SettingScreen__Jumio'>
      <div className='VerificationBox'>
        <Icon name='verify' className={`Icon ${verificatioIsSuccess && 'success' || verificatioIsFailed && 'warning'} `} />
        {verificatioIsSuccess && <Icon name='success' className='IconStatus success' />}
        {verificatioIsFailed && <Icon name='warning' className='IconStatus warning' />}
      </div>
      {!verificatioIsSuccess &&
        <Button
          type = 'a'
          target='_blank'
          href={link}
          disabled={isDisabledButton}
          loading={isLoading}
          className='ButtonVerification'
          secondary
          onClick={handleClick}
        >
          {t(M.start)}
        </Button>}
      {status && textStatus && <p className='description'>{t(textStatus)}</p>}
    </div>)
}

const mapState = state => ({
  cards: state.card,
  status: state.document.jumioStatus,
  link: state.document.jumioLink
});

const mapDispatch = dispatch => ({
  actions: {
    jumio: bindActionCreators(jumioActions, dispatch),
  }
});

export default injectIntl(connect(mapState, mapDispatch)(JumioVerification));
