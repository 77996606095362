export const languages = [
	{
		value: 'de',
		title: 'Deutsch',
		subtitle: 'German',
	},
	{
		value: 'en',
		title: 'English',
		subtitle: 'English',
	},
	{
		value: 'lv',
		title: 'Latviešu',
		subtitle: 'Latvian',
	},
	{
		value: 'ru',
		title: 'Pусский',
		subtitle: 'Russian',
	},
	{
		value: 'pl',
		title: 'Polski',
		subtitle: 'Polish',
	},
	{
		value: 'ee',
		title: 'Eesti',
		subtitle: 'Estonian',
	},
	{
		value: 'lt',
		title: 'Lietuviškai',
		subtitle: 'Lithuanian',
	},
	{
		value: 'it',
		title: 'Italian',
		subtitle: 'Italiano',
	},
	{
		value: 'nl',
		title: 'Dutch',
		subtitle: 'Nederlands',
	},
	{
		value: 'es',
		title: 'Español',
		subtitle: 'Spanish',
	},
	{
		value: 'cs',
		title: 'Česky',
		subtitle: 'Czech',
	},
	{
		value: 'bg',
		title: 'Български',
		subtitle: 'Bulgarian',
	},
];
