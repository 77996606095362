import React, { Component } from 'react';
import Title from 'components/Title';
import InputToggle from 'components/InputToggleSwitch';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import helpers from 'helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  { timeDifferenceMinutes }  from 'helpers/timeDifference';

import * as countryActions from 'actions/country';
import * as twoFactorActions from 'actions/twoFactor';
import { SuccessIcon } from 'components/icons';
import SendCode from './SendCode';
import QuestionModal from './QuestionModal';

import M from './TwoFactorAuthentication.locale.json';

import './TwoFactorAuthentication.scss';

class TwoFactorAuthentication extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disableResend: false,
			loader: false,
			timeResend: 60,
			isSuccessfullChange: false,
			isChecked: !!props.user.twoFactor,
			isActiveModel: false,
			isTwoFactorCode: false,
			isFirstPage: true,
			countryCode: '',
			userPhone: '',
			currentOperation: '',
			currentToken:0,
			isWarning: false,
			form: {
				code: ''
			},
			errors: {
				code: []
			}
		};
	}

	timeout = 0;

	componentDidMount() {
		this.onMount();
	}

	componentWillUnmount() {
		clearTimeout(this.timeout)
	}

	componentDidUpdate() {

		if (!this.state.countryCode) {
			this.updateUserPhone();
		}
	
	}

	updateUserPhone = () => {
		const countryCode = this.props.phoneCountry.reduce((acc, rec) => {
			if (rec.id === this.props.user.phoneCountry) {
				return `+${rec.phoneCode}`;
			}
			return acc;
		}, '');

		if (countryCode) {
			const userPhone = `${countryCode}${this.props.user.phone}`;
			this.setState({ userPhone, countryCode });
		}
	};

	onMount = () => {
		this.updateUserPhone();
	};

	onChangeForm = (value, name) => {
		const { form, errors } = this.state;
		let messages = [];
		if (errors[name].length) {
			messages = helpers.validator.single(
				{
					...form,
					[name]: value
				},
				name,
				{
					code: {
						presence: { message: '^schemas.presence' }
					}
				}
			);
		}

		this.setState({
			form: {
				...form,
				[name]: value
			},
			errors: {
				...errors,
				[name]: messages
			}
		});
	};

	onChangeChecked = checked => {

		if (checked) {
			this.setState({ currentOperation: 'enable' });
			this.setState({ isWarning: checked });
		} else {
			this.setState({ currentOperation: 'disable' });
			this.goToNextSection();
		}

	};

	goToQuestionModal = () => {
		this.setState({ isWarning: false, isActiveModel: true });
	}

	goToСhangePhone = () => {

		this.setState({
			isChecked: false,
			isActiveModel: false
		});

		this.props.history.push('/settings/phone');
	};

	goToNextSection = async () => {
		const { actions } = this.props
		this.setState({
			isActiveModel: false,
			isFirstPage: false,
			isTwoFactorCode: true
		});
		const lastRequest = await actions.twoFactor.getLastRequestSetting();
		this.setState({ currentToken: lastRequest.created });
		this.checkResendTime();
	};

	sendCode = async e => {
		e.preventDefault();
		const { errors, currentOperation, form } = this.state;
		const { actions, history } = this.props;

		const validateErrors = helpers.validator.all(form, {
			code: {
				presence: { message: '^schemas.presence' }
			}
		});

		if (validateErrors) {
			this.setState({ errors: { ...errors, ...validateErrors } });
			return;
		}

		this.setState({ loader: true });

		const response =
			currentOperation === 'disable'
				? await actions.twoFactor.disableTwoFactor(form)
				: await actions.twoFactor.enableTwoFactor(form);

		if (response && response.errors) {
			const defaultMessage = response.errors.code[0].defaultMessage
			const errorField = {
				code:[
					{id: defaultMessage === 'Invalid verification code' ?
				 		'schemas.two.factor.code.invalid' :
						'schemas.two.factor.code.expired'
					}
				]
			};

			this.setState({ errors: { ...errors, ...errorField } });
			this.setState({ loader: false });

			return;
		}

		if (currentOperation === 'disable') {
			this.setState ({
				disableResend: false,
				loader: false,
				timeResend: 60,
				isSuccessfullChange: false,
				isChecked: !!this.props.user.twoFactor,
				isActiveModel: false,
				isTwoFactorCode: false,
				isFirstPage: true,
				countryCode: '',
				userPhone: '',
				currentOperation: '',
				form: {
					code: ''
				},
				errors: {
					code: []
				}
			});
		} else {
			this.setState({isFirstPage: false, isSuccessfullChange:true, isTwoFactorCode:false, });
			this.timeout = setTimeout(()=>{
				history.push('/')
			}, 10000)
		}
	};

	onDisable = (time = 60) => {
		this.setState({ loader: false, disableResend: true, timeResend: Math.ceil(time) });
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({
				loader: false,
				disableResend: false,
				timeResend: 0
			});
		}, time * 1000);
	};

	checkResendTime = () => {
		const lastRequestDate = this.state.currentToken;
		const currentDate = new Date();
		const result = timeDifferenceMinutes(currentDate, lastRequestDate);

		if (result <= 1) {
			this.onDisable(60 * (1 - result));
			return false;
		}

		this.onDisable();
		this.props.actions.twoFactor.generateCode();
	}

	onResend = async() => {
        
		if (!this.state.disableResend) {
			this.onDisable();
			await this.props.actions.twoFactor.generateCode();
		}

	};

	render() {
		const t = this.props.intl.formatMessage;
		const {
			isTwoFactorCode,
			isChecked,
			isActiveModel,
			userPhone,
			form,
			errors,
			loader,
			timeResend,
			disableResend,
			isFirstPage,
			isSuccessfullChange,
			countryCode,
			isWarning
		} = this.state;
	
		return (
			<div className='TwoFactor'>
				<div className='TwoFactor__Content'>
					{!isSuccessfullChange && (
						<Title title={`${t(M.information.title)}`} />
					)}
					{isFirstPage && (
						<div>
							<div className='TwoFactor__ToggleSwitch'>
								<p className='TwoFactor__Title'>
									{t(M.information.title)}
								</p>
								<InputToggle
									checked={isChecked}
									onChange={this.onChangeChecked}
									id='ToggleSwitch'
								/>
							</div>
							<p className='TwoFactor__Text'>
								{t(M.information.firstText)}
							</p>
							<p className='TwoFactor__Question'>
								{t(M.information.question)}
							</p>
							<p className='TwoFactor__Text'>
								{t(M.information.answer)}
							</p>
						</div>
					)}
				</div>
				{isTwoFactorCode && (
					<SendCode
						textInputPlaceHolder={t(M.sendCode.placeholder)}
						textButtonSend={t(M.sendCode.buttonSend)}
						textButtonResend={t(M.sendCode.buttonResend)}
						valueInput={form.code}
						onChange={this.onChangeForm}
						textAboutSendCode={`${t(
							M.sendCode.aboutSendCode
						)} ${userPhone}`}
						sendCode={this.sendCode}
						loader={loader}
						error={errors.code}
						timeResend={timeResend}
						informationResend={t(M.sendCode.informationResend)}
						onResend={this.onResend}
						disableResend={disableResend}
					/>
				)}

				{isActiveModel && (
					<QuestionModal
						goToСhangePhone={this.goToСhangePhone}
						userPhone={userPhone}
						goToNextSection={this.goToNextSection}
					/>
				)}
				{isSuccessfullChange && (
					<div className='TwoFactor__Success'>
						<SuccessIcon width='100px' height='100px' />
						<p>{`${t(M.success.information)}`}</p>
					</div>
				)}
				{isWarning && <QuestionModal goToNextSection={this.goToQuestionModal} isWarning={isWarning}/>}
			</div>
		);
	}
}

const mapState = (state, props) => ({
	user: state.user,
	phoneCountry: state.country
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		twoFactor: bindActionCreators(twoFactorActions, dispatch)
	}
});

export default connect(
	mapState,
	mapDispatch
)(withRouter(injectIntl(TwoFactorAuthentication)));
