import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';

import * as clientSelector from 'selectors/client';

import * as clientActions from 'actions/client';
import * as authActions from 'actions/auth';

import Logo from 'components/Logo';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Client from 'components/Client';

import M from './Sidebar.locale.json';
import axios from 'axios';

import './Sidebar.scss';
import { API } from '../../utils/api-service';



const mapState = state => ({
	current: clientSelector.getCurrentClient(state),
	user: state.user,

	// unreadCount: state.notify.unreadCount
});

const mapDispatch = dispatch => ({
	actions: {
		client: bindActionCreators(clientActions, dispatch),
		auth: bindActionCreators(authActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Sidebar extends PureComponent {
	state = {
		collapsed: false,
		width: window.innerWidth,
		unreadCount: null
	};

	getUnreadCount = async () => {

		const { data: { response } } = await axios.request({
			method: `get`,
			url: `/notification/getUnreadCount`,
			headers: {
				Authorization: localStorage.getItem(`session`).slice(1, localStorage.getItem(`session`).length - 1)
			}
		})

		this.setState({
			unreadCount: response.unread
		});
	}


	componentDidMount = () => {
		window.addEventListener('resize', this.updateDimensions);

		if (this.state.width < 960) {
			this.setState({
				collapsed: true
			});
		}

		this.getUnreadCount()

		this.unreadCountInterval = setInterval(() => this.getUnreadCount(), 120000)


	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateDimensions);
		clearInterval(this.unreadCountInterval)
	}

	updateDimensions = () => {
		this.setState({
			width: window.innerWidth
		});

		if (this.state.width < 960) {
			this.setState({
				collapsed: true
			});
		} else {
			this.setState({
				collapsed: false
			});
		}
	};

	onCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed
		});
	};

	onLogout = () => {
		const { auth } = this.props.actions;

		auth.logout();
	};

	onSettings = () => {
		const { history } = this.props;

		history.push('/settings');
	};

	render() {
		let className = ['Sidebar'];

		const { current, logo,user } = this.props;
		if (this.props.className) {
			className = [
				...className,
				...this.props.className
			];
		}

		const access = (current.role === 'OWNER');
		const isPrivate = (current.roleType === 'PRIVATE');

		const t = this.props.intl.formatMessage;

		// collapse sidebar on click
		if (this.state.collapsed) {
			className.push('Collapsed');
		}


		return (
			<div
				className={className.join(' ')}
			>
				<div
					className="Sidebar__Logo"
				>
					<Logo />
				</div>
				{
					!this.state.collapsed &&
					<Client className="Sidebar__Nav__Client" />
				}

				<div className="Sidebar__Primary">
					<Button onClick={this.onCollapse}>
						{!this.state.collapsed &&
							<Icon name="close" />
						}
						{this.state.collapsed &&
							<Icon name="menu" />
						}
					</Button>
					<ul>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/"
								exact
							>
								<Icon name="nav-dashboard" /><span>{t(M.nav.dashboard)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/history"
							>
								<Icon name="nav-history" /><span>{t(M.nav.history)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/cards"
							>
								<Icon name="nav-cards" /><span>{t(M.nav.cards)}</span>
							</NavLink>
						</li>

						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/payments"
							>
								<Icon name="nav-payments" /><span>{t(M.nav.payments)}</span>
							</NavLink>
						</li>

						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/notifications"
							>
								<Icon name="nav-notifications" /><span>Notifications {this.state.unreadCount ? <span className="unread">{this.state.unreadCount}</span> : null}</span>
							</NavLink>
						</li>
						{user && user.sum_sub_status === 'verify' && <li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/exchange"
							>
								<Icon name="nav-exchange" /><span>{t(M.nav.exchange)}</span>
							</NavLink>
						</li>}
						<li>
							{
								(access && !isPrivate) &&
								<NavLink
									className="Sidebar__Link"
									activeClassName="Sidebar__Link--Current"
									to="/members"
								>
									<Icon name="nav-team" /><span>{t(M.nav.members)}</span>
								</NavLink>
							}

							{
								(!access && !isPrivate) &&
								<div
									className="Sidebar__Link Sidebar__Link--Disabled"
								>
									<Icon name="nav-team" /><span>{t(M.nav.members)}</span>
								</div>
							}
						</li>
					</ul>
					<div className="User-actions">
						<ul>
							<li>
								<NavLink
									className="Sidebar__Link"
									activeClassName=""
									to="/settings"
									exact
								>
									<Icon name="settings" /><span>{t(M.nav.settings)}</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									className="Sidebar__Link"
									activeClassName=""
									onClick={this.onLogout}
									to=""
									exact
								>
									<Icon name="logout" /><span>{t(M.nav.logout)}</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

Sidebar.propTypes = {
	className: PropTypes.arrayOf(PropTypes.string),
};
