import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Linkify from 'components/Linkify';
import Button from 'components/Button';
import CheckBox from 'components/InputCheckbox';

import M from './Beneficiary.locale.json';

const BeneficiaryModal = ({ intl, modalInfo, changeBic, nextStep }) => {
  const t = intl.formatMessage;
  const [isNotShow, setNotShow] = useState(false);

  const onCorrect = () => {
    nextStep(isNotShow);
  };

  const onChangeBic = () => {
    changeBic(isNotShow)
  };

  const notShowAnymore = (value) => {
    setNotShow(value);
  };

  return (
    <Modal>
      <Section>
        <Container medium>
          <Wrap className='PaymentCreateScreen__Modal'>
            <div className='Content'>
            <h2 className='PaymentCreateScreen__ModalMessage'>
              <Linkify>
                {modalInfo.message}
              </Linkify>
            </h2>
            </div>
            <div className='Buttons'>
              <div className='Buttons__firstSection'>
                <Button
                  onClick={onCorrect}
                  small
                  className='ui-modal__button'
                >
                 {t(M.buttons.BICCorrect)}
                </Button>
                <Button
                  onClick={onChangeBic}
                  small
                  className='ui-modal__button'
                >
                  {t(M.buttons.changeBIC)}
                </Button>
              </div>
              <CheckBox className='PaymentCreateScreen__CheckBox' type='notShow' name='notShow' checked={isNotShow} value="Don't show this anymore" onChange={notShowAnymore}>
                {t(M.buttons.notShowAnymore)}
              </CheckBox>
            </div>
          </Wrap>
        </Container>
      </Section>
    </Modal>
  );
};

export default injectIntl(BeneficiaryModal);
