export const SERVER_URL = `http://api-dev2.weststeincard.com/api/private/crypto`;

export const StatusCode = {
	NO_DATA: 201
};

export const Path = {
	DEFAULT: `/`
};

export const CryptoUrl = {
	SELL: `/sell`,
	SEND: `/send`
};

export const UserUrl = {
	USER_INFO: `/user_info`,
};

export const InfoUrl = {
	TRADE_INFO: `/trade_info`
};

export const FiatUrl = {
	BUY: `/buy`
};

export const ResponeType = {
	BLOB: `blob`
};

export const Method = {
	GET: `GET`,
	POST: `POST`,
	PUT: `PUT`,
	DELETE: `DELETE`
};

export const AuthorizationStatus = {
	AUTH: `auth`,
	NO_AUTH: `notAuth`
};

export const OperationType = {
	CRYPTO_TO_FIAT: 1,
	FIAT_TO_CRYPTO: 2,
	CRYPTO_TO_CRYPTO: 3
};

export const CookieName = {
	USER_ID: `user_id_west`
};

export const operationTypes = [
	{
		value: OperationType.CRYPTO_TO_FIAT,
		label: `buy`,
		id: 0
	},
	{
		value: OperationType.FIAT_TO_CRYPTO,
		label: `sell`,
		id: 1
	},
	{
		value: OperationType.CRYPTO_TO_CRYPTO,
		label: `send`,
		id: 2
	}
];

export const UserType = {
	UNSET: `unset`,
	EXISTS: `exists`,
	CREATE: `create`
};

export const TradeType = {
	EUR_USDT: `EUR-USDT`,
	USDT_EUR: `USDT-EUR`
};

export const eventTypes = [
	{
		label: `Пополнение счета`,
		value: `REFILL`
	},
	{
		label: `Расходы`,
		value: `COSTS`
	},
	{
		label: `Верификация пользователя`,
		value: `VERIFICATION`
	},
	{
		label: `Заканчивается срок действия карты`,
		value: `EXPIRING_CARD`
	},
	{
		label: `Текстовые уведомления`,
		value: `TEXT_NOTIFICATION`
	}
];

export const EventType = {
	REFILL: `REFILL`,
	COSTS: `COSTS`,
	VERIFICATION: `VERIFICATION`,
	EXPIRING_CARD: `EXPIRING_CARD`,
	TEXT_NOTIFICATION: `TEXT_NOTIFICATION`
};
