import React from "react";
import { useEscHandler } from "../../hooks/UseEscModal";
import { injectIntl } from "react-intl";

import "../ExchangeModal/ExchangeModal.scss";

const SendModal = ({ setModal, submitFormHandler, intl, eur, tron , transaction}) => {
	const [closeHandler] = useEscHandler(setModal);

	const sendHandler = evt => {
		submitFormHandler(evt);
		closeHandler();
	};

	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">
				<p className="exchange-modal__text">
					{intl.messages["exchange.send.modal"]} {tron} {intl.messages["exchange.create_exchange.usdt"]} {intl.messages["exchange.send.modal.to"]} {transaction.transferWallet}
				</p>

				<button
					className="exchange__button exchange-modal__button"
					type="button"
					onClick={sendHandler}
				>
					{intl.messages["exchange.modal.awesome"]}
				</button>
			</section>
		</div>
	);
};

export default injectIntl(SendModal);
