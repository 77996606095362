import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';

export default {
	login (form) {
		return fetcher.post('auth/login', {}, { params: form });
	},
	logout() {
		return fetcher.post('user/logout');
	},
	loginCreditCard (form) {
		return fetcher.post('open/login-card-number', {}, { params: form });
	},
	activate (form) {
		return fetcher.post('open/activate-card-number', {}, { params: form });
	},
	signup: {
		private (form) {
			const convert = renamer.to.auth.signupPrivate(form);
			return fetcher.post('apply/private', convert);
		},
		business (form) {
			let convert = renamer.to.user(form);
			convert = renamer.to.business.signup(convert);
			return fetcher.post('apply/business', convert);
		},
	},
	email (form) {
		return fetcher.post(`user/confirm/${form.code}`);
	},
	reset (form) {
		return fetcher.post('reset/request', {}, { params: form });
	},
	password (form, token) {
		return fetcher.post(`reset/${token}`, {}, { params: form });
	},
};
