import {
	LANGUAGE_GET_SUCCESS,
	LANGUAGE_EDIT_SUCCESS,
} from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import place from 'services/place';
import normalize from 'helpers/locales';
import M from './language.locale.json';

export const get = locale => async dispatch => {
	try {
		const result = await API.language.get(locale.toLowerCase());
		place.language(locale);
		dispatch({ type: LANGUAGE_GET_SUCCESS, payload: { locale, messages: result.data } });
		return result.data;
	} catch (err) { return err; }
};

export const edit = locale => async dispatch => {
	try {
		const { data: { response } } = await API.language.edit(normalize.toBackend(locale));
		place.language(locale);
		const messages = await API.language.get(locale);
		dispatch({ type: LANGUAGE_EDIT_SUCCESS, payload: { locale, messages: messages.data } });
		success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.edit.title, type: 'error' });
		return err;
	}
};
