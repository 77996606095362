import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import intlTypes from 'types/intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import WarningModal from './WarningModal';
import './QuestionModal.scss';

const QuestionModal = ({ intl, isLoading, goToСhangePhone, userPhone, goToNextSection, isWarning }) => {
	const t = intl.formatMessage;

	return (
		<Modal>
			<Section>
				<Container medium>
					<Wrap className='SingleWarning__wrap'>
						{!isWarning ? 
							<React.Fragment >
								<Title
									title=''
									subtitle={`${t({ id: 'screens.setting.setting.two.factor.popup' })} ${userPhone}?`}
									small
									className='SingleWarning-text'
								/>
								<div className='SingleWarning-controls'>

									<Button
										onClick={goToNextSection}
										small
										className='SingleWarning-button'
										loading={isLoading}
									>
										<FormattedMessage id='card.renew.button.yes' />
									</Button>

									<Button
										onClick={goToСhangePhone}
										small
										className='SingleWarning-button'
										disabled={isLoading}
									>
										<FormattedMessage id='card.renew.button.no' />
									</Button>
								</div>
							</React.Fragment> : <WarningModal t={t} goToNextSection={goToNextSection} />}
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
};

QuestionModal.propTypes = {
	goToСhangePhone: PropTypes.func,
	userPhone: PropTypes.string,
	goToNextSection: PropTypes.func,
	isLoading: PropTypes.bool,
	intl: intlTypes.isRequired
};


export default injectIntl(QuestionModal);
