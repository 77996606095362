import React from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Nav from 'sections/Nav';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Button from 'components/Button';
import Title from 'components/Title';
import * as cardActions from 'actions/card';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';
import M from './RegulatoryDataCollection.locale';
import tracker from 'helpers/tracker';
import { UTMParams } from 'constants/common';

import intlTypes from 'types/intl';

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@injectIntl
@connect(null, mapDispatch)
class RegulatoryDataCollection extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			card: PropTypes.shape({
				setRegulatoryDataCollection: PropTypes.func.isRequired,
			}).isRequired
		}).isRequired
	};

	state = {
		stepCompleted: false,
	};

	componentDidMount () {
		triggerEvent(GTMEvent.regulatoryData, '/auth/regulatory-data-collection');

		document.addEventListener('mousemove', tracker.onMouseMove);
	}

	componentWillUnmount () {
		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onSubmit = data => {
		this.fixateTrackResult();
		
		this.props.actions.card.setRegulatoryDataCollection(data);
		this.setState({
			stepCompleted: true,
		});
	};

	fixateTrackResult = () => {
		const result = tracker.analyse();
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
	};

	render () {
		const { stepCompleted } = this.state;
		const t = this.props.intl.formatMessage;

		if (stepCompleted) return <Redirect to={{ pathname: '/auth/private' }} />;

		return (
			<Layout className="RegulatoryDataCollectionScreen Screen">
				<Nav logo />
				<Section>
					<Container small>
						<Title title={t(M.title)} />
						<Form.Note>{t(M.subtitle)}</Form.Note>
						<RegulatoryDataCollectionForm
							onSubmit={this.onSubmit}
							button={<Button form small>{t(M.buttons.next)}</Button>}
							onKeyDown={tracker.onKeyboardEvent}
						/>
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default RegulatoryDataCollection;
