import M from './Setting.locale.json';

export const nav = [
	{
		id: 'account',
		title: M.nav.account,
		icon: 'account',
	},
	{
		id: 'interface',
		title: M.nav.interface,
		icon: 'interface',
	},
	{
		id: 'phone',
		title: M.nav.phone,
		icon: 'phone',
	},
	{
		id: 'password',
		title: M.nav.password,
		icon: 'password',
	},
	{
		id: 'security',
		title: M.nav.security,
		icon: 'security',
	},
	// WS3-1299 Hide "Notification settings"
	// {
	// 	id: "notification",
	// 	title: M.nav.notification,
	// 	icon: "bell",
	// },
	{
		id: 'document',
		title: M.nav.document,
		icon: 'id-tag',
	},
	{
		id: 'applications',
		title: M.nav.applications,
		icon: 'applications',
	},
	{
		id: 'regulatoryData',
		title: M.nav.regulatoryData,
		icon: 'personal-task',
	}
];
