import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as cardSelector from 'selectors/card';

import Section from 'components/Section';
import Container from 'components/Container';

import Wrap from 'components/Wrap';
import BlockingScreen from '../../screens/Blocking';

import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import intlTypes from 'types/intl';

import M from './Load.locale.json';
import PropTypes from 'prop-types';

import './Load.scss';

import helpers from 'helpers';
import userType from 'types/user';
import * as userSelector from 'selectors/user';

const mapState = state => ({
	currentCard: cardSelector.getById(state, state.card.current),
	unverifiedCards: cardSelector.getUnverifyCards(state),
	currentUser: userSelector.getUser(state),
});

const documentUrl = '/settings/document';
const SUPPORTED_PRODUCT_TYPES = ['CARD_04', 'CARD_05', 'CARD_06', 'CARD_07', 'CARD_08'];

@withRouter
@injectIntl
@connect(mapState)
export default class Sepa extends Component {
	static propTypes = {
		currentCard: currentCardTypes,
		history: historyTypes.isRequired,
		unverifiedCards: PropTypes.arrayOf(currentCardTypes),
		intl: intlTypes.isRequired,
		currentUser: userType,
	}

	state = {
		loader: false
	};

	onVerifyCard = () => this.props.history.push(documentUrl)

	isUnverifiedCardPresent = () => {
		const { unverifiedCards } = this.props;

		return !!(unverifiedCards && unverifiedCards.length);
	}

	renderNoIBAN = () => (
		<React.Fragment>
			{' '}
			<span>Please</span>
			{' '}
			<a className="VerifyLink" onClick={this.onVerifyCard}>verify</a>
			{' '}
			<span>your card to see IBAN.</span>
		</React.Fragment>
	)

	render () {
		const { currentCard, currentUser } = this.props;
		const t = this.props.intl.formatMessage;
		const { iban, bic, cardName } = currentCard;

		return SUPPORTED_PRODUCT_TYPES.includes(currentCard.product) || helpers.checker.isSU(currentUser) ? (
			<div>
				<h3>{t(M.sepa.title)}</h3>
				{t(M.sepa.description)}
				<br />
				<ol>
					<li>{t(M.sepa.stepOne)}</li>
					<li>{t(M.sepa.stepTwo)}<br />
						<strong>{t(M.sepa.recipient)}</strong> {cardName}<br />
						<strong>IBAN:</strong> {
							this.isUnverifiedCardPresent() ?
								this.renderNoIBAN() :
								iban
						} <br />
						<strong>BIC/SWIFT:</strong> {bic}
					</li>
					<li>{t(M.sepa.stepThree)}</li>
					<li>{t(M.sepa.stepFour)}</li>
				</ol>

				<strong>{t(M.sepa.hintTitle)}</strong> {t(M.sepa.hint)}<br />
				<strong>{t(M.sepa.rememberTitle)}</strong> {t(M.sepa.remember)}
			</div>
		) : (
			<Section className="LoadSection">
				<Container>
					<Wrap><BlockingScreen /></Wrap>
				</Container>
			</Section>
		);
	}
}
