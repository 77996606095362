import React from "react";
import { EventType, eventTypes } from "../../constants/exchange-const";
import "./NotificationItem.scss";
import { injectIntl } from "react-intl";
import { API } from "../../utils/api-service";

const NotificationItem = ({ item, intl }) => {
	const { locale } = intl;

	let amount, balance, card_number, currency, name_on_card;
	let text, title;

	let notificationTitle;

	const { eventType, date, parameters, processStarted, unread, id } = item;
	
	switch (eventType) {
		case EventType.TEXT_NOTIFICATION:
			text = JSON.parse(parameters.text)[locale.toUpperCase()];
			title = JSON.parse(parameters.title)[locale.toUpperCase()];
			notificationTitle = JSON.parse(parameters.title)[
				locale.toUpperCase()
			];

			break;
		case EventType.EXPIRING_CARD:
			card_number = parameters.card_number;
			notificationTitle = intl.messages["notify.expiring.card.title"];
			break;
		case EventType.COSTS:
			amount = parameters.amount;
			balance = parameters.balance;
			card_number = parameters.card_number;
			currency = parameters.currency;
			name_on_card = parameters.name_on_card;
			notificationTitle = intl.messages["notify.costs.title"];
			break;
		case EventType.REFILL:
			amount = parameters.amount;
			balance = parameters.balance;
			card_number = parameters.card_number;
			currency = parameters.currency;
			name_on_card = parameters.name_on_card;
			notificationTitle = intl.messages["notify.refill.title"];
			break;
		default:
			break;
	}

	const reissueCard = async () => {
		await API({
			method: `POST`,
			url: `/notification/${id}/startProcess`,
		});
	};

	return (
		<div className="notification-item">
			<h3 className="notification-item__title">{notificationTitle}</h3>
			{unread && <span className="notification-item__unread"></span>}
			<div className="notification-item__body">
				{eventType === EventType.TEXT_NOTIFICATION && (
					<React.Fragment>
						<span>{text}</span>
					</React.Fragment>
				)}
				{eventType === EventType.EXPIRING_CARD && (
					<React.Fragment>
						<span>{card_number}</span>
						<span>{intl.messages["notify.new.card.prompt"]}</span>
					</React.Fragment>
				)}
				{eventType !== EventType.EXPIRING_CARD &&
					eventType !== EventType.TEXT_NOTIFICATION && (
						<React.Fragment>
							<span>amount: {amount}</span>
							<span>balance: {balance}</span>
							<span>card_number: {card_number}</span>
							<span>currency: {currency}</span>
							<span>name_on_card: {name_on_card}</span>
						</React.Fragment>
					)}
			</div>

			<span className="notification-item__date">{date}</span>

			{eventType === EventType.EXPIRING_CARD && processStarted && (
				<span className="notification-item__process-started">
					{intl.messages["notify.new.card.process.started"]}
				</span>
			)}
			{eventType === EventType.EXPIRING_CARD && !processStarted && (
				<button
					onClick={reissueCard}
					className="notification-item__process-start-button"
				>
					{intl.messages["notify.new.card.process.start.button"]}
				</button>
			)}
		</div>
	);
};

export default injectIntl(NotificationItem);
