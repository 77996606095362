import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Timer.scss';

class Timer extends React.PureComponent {
	static propTypes = {
		time: PropTypes.number,
		className: PropTypes.string,
	}

	state = {
		time: this.props.time || 30,
	}
    
	componentDidMount () {
		this.interval = setInterval(this.onInterval, 1000);
	}

	componentWillUnmount () {
		clearInterval(this.interval);
	}

	onInterval = () => {
		const { time } = this.state;
		if (time <= 0) return clearInterval(this.interval);
		return this.setState({ time: (time - 1) });
	}

	render () {
		const { time } = this.state;
		const { className } = this.props;
		return (
			<span className={classnames('Timer', className)}>
				{time}
			</span>
		);
	}
}

export default Timer;
