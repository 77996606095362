import API from 'services/api';
import { 
	NOTIFICATIONS_SET,
	NOTIFICATIONS_LOCK, 
	NOTIFICATIONS_UNLOCK, 
	NOTIFICATIONS_TYPE_WARNING,
	NOTIFICATIONS_REQUEST_INTERVAL,
} from 'constants';

let interval;

export const get = () => async (dispatch, getState) => {
	const isLocked = getState().locked;
	if (!isLocked) {
		try {
			const { data } = (await API.notifications.getUnread());
			const messages = data.response.map(message => {
				if (!message.type) {
					return { ... message, type: NOTIFICATIONS_TYPE_WARNING }; // at the time of implementation, I do not receive the "type" field, but it should be "WARNING"
				}
			});
			
			dispatch({ type: NOTIFICATIONS_SET, payload: messages });
			
			return { messages, status: 200 };
		} catch (err) {
			return err; 
		}
	}
	return { messages: getState().notification.list, status: 200 };
};
 
export const markAsRead = notifications => async (dispatch, getState) => {	
	try {
		const ids = notifications.map(notification => notification.id);

		const tempNotificationList = getState()
			.notifications
			.list
			.filter(notification => !ids.includes(notification.id));
		
		dispatch({ type: NOTIFICATIONS_SET, payload: tempNotificationList });
			
		dispatch({ type: NOTIFICATIONS_LOCK });
		const response = await API.notifications.markAsRead(ids);
		dispatch({ type: NOTIFICATIONS_UNLOCK });

		return response;
	
	} catch (err) { return err; }
};

export const startTracking = () => async (dispatch, getState) => {
	try {
		const firstData = await get()(dispatch, getState);
		if (firstData.status === 200) {
			interval = setInterval(() => dispatch(get()), NOTIFICATIONS_REQUEST_INTERVAL);
		}
		return firstData;
	} catch (err) { return err; }
};

export const alreadyCard = (id) => async () => {
	try {
		const { data: { response } } = await API.notifications.alreadyCard(id);
		return response;
	} catch (error) {
		return error;
	}
};

export const cancelOrderingCard = (id) => async () => {
	try {
		const { data: { response } } = await API.notifications.cancelOrderingCard(id);
		return response;
	} catch (error) {
		return error;
	}
};

export const stopTracking = () => () => {
	clearInterval(interval);
};