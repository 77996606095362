import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Card from 'components/Card';
import Tab from 'components/Tab';
import {
	card as commonCard,
	cardStatus,
	clientRole,
	IS_VIRTUAL_CARD_MAINTENANCE_MODE_ACTIVE,
	CLIENT_TYPE,
} from 'constants/common';
import * as cardActions from 'actions/card';
import * as modalsActions from 'actions/modals';
import { getById, getBalance } from 'selectors/card';
import { getCurrentClient } from 'selectors/client';
import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import { cardActionsTypes, cardModalActionsTypes } from 'types/actions';
import clientTypes from 'types/client';
import {
	isPhysicalCard,
	isVirtualCard,
	isCardExpiated,
	getClientType,
} from 'services/card';
import PINPanel from './PIN';
import ActivatePanel from './Activate';
import BlockPanel from './Block';
import GetCVV from './GetCVV';
import GetPIN from './GetPIN';
import GetPlastic from './GetPlastic';
import SuccessGetPlasticModal from './GetPlastic/ConfirmGetPlasticModal';
import Reissue from './Reissue';
import ReissueModal from './ReissueModal';
import WarningModal from './WarningModal';

import { cardsUrl, REISSUE_MODAL_STATE, loadUrl } from './constants';

import M from './Single.locale.json';
import './Single.scss';
import { getIsUserVerified } from '../../../selectors/user';

const cards = [commonCard.CARD04, commonCard.CARD05, commonCard.CARD06];

const mapState = (state, props) => ({
	client: getCurrentClient(state),
	card: getById(state, props.match.params.id),
	balance: getBalance(state),
	isUserVerified: getIsUserVerified(state)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		modals: bindActionCreators(modalsActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
class Single extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
			modals: cardModalActionsTypes.isRequired
		}).isRequired,
		history: historyTypes.isRequired,
		match: PropTypes.object.isRequired,
		client: clientTypes.isRequired,
		balance: PropTypes.number.isRequired,
		isUserVerified: PropTypes.bool
	};

	constructor (props) {
		super(props);

		this.setReissueState = this.setReissueState.bind(this);
	}

	state = {
		loaderPage: true,
		verified: cardStatus.VERIFIED,
		isSuccessModalVisible: false,
		reissueModalState: REISSUE_MODAL_STATE.CLOSE,
		underResourcedPage:false,
		warning:false,
		isLoading:false
	};

	componentDidMount () {
		this.mounted = true;
		this.onMount();
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	// eslint-disable-next-line consistent-return
	onMount = async () => {
		const { history, match, card } = this.props;
		const { tab } = match.params;
		const isVirtual = isVirtualCard(card);
		if (isVirtual && IS_VIRTUAL_CARD_MAINTENANCE_MODE_ACTIVE) {
			this.props.actions.modals.toggleMaintenanceModal();
			history.goBack();

			return;
		}

		this.setState({
			tab,
			...(cards.includes(card.product) ? { verified: cardStatus.UNVERIFIED } : {})
		});

		this.mounted && this.setState({ loaderPage: false });
	};
  
	onTab = tab => {
		const { history, match } = this.props;
		const { id } = match.params;
		history.push(`${cardsUrl}/${id}/${tab.id}`, { modal: true, replace: true });
	};
 
	onGetPlasticCardClick = async () => {
  	const {
  		actions,
  		card,
  	} = this.props;
 
  	this.setState({ isLoading: true });

  	actions.card
  		.updateVirtualToPlastic(card)
  		.then(() => {
  			this.toggleSuccessGetPlasticModal();
  		})
  		.catch(e => console.error(e))
			.finally(()=> {
				this.setState({ isLoading: false, underResourcedPage:false, warning:false  });
		});	
	};

	async setReissueState (newState, errors) {
		const { reissueModalState } = this.state;
		const { actions, history, card } = this.props;
		const { SUCCESS, ERROR, ERROR_FEE } = REISSUE_MODAL_STATE;
		
		if ([ SUCCESS, ERROR ].includes(reissueModalState)) {
			this.setState({ loaderPage: true });
			history.replace(`${cardsUrl}/${card.id}`, { modal: true, replace: true });
			await actions.card.fetch(false);
			this.setState({ loaderPage: false });
		}
		
		if (newState === ERROR && errors && errors.availBal) {
			this.setState({
				reissueModalState: ERROR_FEE,
			});

			return;
		}

		this.setState({
			reissueModalState: newState,
		});
	}
	
  
 	setCloseWarningModal = () => {
  	this.setState({ warning:false, underResourcedPage:false });
  	this.pop();
 	}

	toggleSuccessGetPlasticModal = () => this.setState(state => ({
		...state,
		isSuccessModalVisible: !state.isSuccessModalVisible
	}));

	pop = () => this.props.history.push(cardsUrl);

	checkBalance = () => {

		if (this.props.card.availBal < 5) {
			this.setState({ underResourcedPage:true });

			return; 
		}

		this.setState({ warning:true });
	}
  
	goToLoadPage = () => {
		this.props.history.push(loadUrl);
	}

	mounted = false;

	render () {

		const { card, match, client, intl } = this.props;

		const {
			loaderPage,
			verified,
			isSuccessModalVisible,
			reissueModalState,
			underResourcedPage,
			warning
		} = this.state;
	
		const { tab } = match.params;
		const t = intl.formatMessage;
		const header = [];
		const isVirtual = isVirtualCard(card);
		const isPhysical = isPhysicalCard(card);
		const isExpiated = isCardExpiated(card);
		const clientType = getClientType(card);
		const canPIN = card.cardStatus === cardStatus.OPEN && isPhysical;
		const canBlock = card.cardStatus === cardStatus.OPEN && isPhysical;
  	const isActivePopUp = warning || underResourcedPage;
		const canReissue = card.cardStatus === cardStatus.OPEN
			&& isExpiated
			&& !card.isReissued
			&& clientType === CLIENT_TYPE.PRIVATE;
			// && (clientType === CLIENT_TYPE.TIANDE
			// 	|| clientType === CLIENT_TYPE.PRIVATE);

		const canActivate = isPhysical &&
			(card.cardStatus === cardStatus.ISSUED ||
				(card.activated === false && card.cardStatus === cardStatus.DEPOSIT_ONLY)
			);

		const notBlocked = card.cardStatus !== cardStatus.BLOCKED;
		const hasAccess = client.role === clientRole.OWNER || client.role === clientRole.PRIVATE;

		if (canPIN) {
			header.push({ id: 'pin', title: t(M.tabs.pin) });
		}

		if (canActivate) {
			header.push({ id: 'activate', title: t(M.tabs.activate) });
		}

		if (notBlocked && canBlock) {
			header.push({ id: 'block', title: t(M.tabs.block) });
		}

		if (isVirtual) {
			header.push(
				{ id: 'cvv', title: t(M.tabs.cvv) },
				{ id: 'getPin', title: t(M.tabs.getPin) },
				{ id: 'getPlastic', title: t({ id: 'card.get.plastic.title' }) }
			);
		}

		if (canReissue) {
			header.push(
				{ id: 'reissue', title: t(M.tabs.renew) },
			);
		}

		let current = null;
		if (tab && header.length) {
			current = header.findIndex(item => item.id === tab);
		} else if (header.length) {
			current = 0;
		}

		return (
			<Modal onClick={this.pop} className="CardSingleScreen Screen">
				<Section>
					<Container medium>
						{!isActivePopUp && card && <Wrap>
							{isSuccessModalVisible && (
								<SuccessGetPlasticModal t={t} onSkip={this.toggleSuccessGetPlasticModal} />
							)}
							<ReissueModal type={reissueModalState} setReissueState={this.setReissueState} card={card} />
							<Modal.Close onClick={this.pop} />
							<div className="CardSingleScreen__Inner">
								<div className="CardSingleScreen__Header">
									<Card card={card} design shadow trunk showCardStatus={false} />
									<div className='CardSingleScreen__StatusContainer'>
										<Card.Status
											className="CardSingleScreen__Status"
											status={card.cardStatus}
											trunk={false}
										/>
										<Card.Status
											className="CardSingleScreen__Status"
											status={card.deliveryType}
											trunk={false}
										/>
									</div>
									<Card.Status
										className="CardSingleScreen__Verified"
										status={verified}
										trunk={false}
									/>
								</div>
								{hasAccess && notBlocked && (
									<Tab header={header} current={current} onChange={this.onTab}>
										{canPIN && (
											<Tab.Panel>
												<PINPanel />
											</Tab.Panel>
										)}
										{canActivate && (
											<Tab.Panel>
												<ActivatePanel />
											</Tab.Panel>
										)}
										{notBlocked && canBlock && (
											<Tab.Panel>
												<BlockPanel />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetCVV />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetPIN />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetPlastic checkBalance={this.checkBalance} toggleSuccessModal={this.toggleSuccessGetPlasticModal} />
											</Tab.Panel>
										)}
										{canReissue && (
											<Tab.Panel>
												<Reissue setReissueState={this.setReissueState} card={card} />
											</Tab.Panel>
										)}

									</Tab>
								)}
							</div>
						</Wrap>
						}
						{loaderPage 
							&& <Modal >
								<Modal.Loader className="CardSingleScreen_Loader" />
							</Modal>
						}
						{isActivePopUp &&
							<WarningModal 
								setCloseModal={this.setCloseWarningModal} 
								warning={warning} 
								underResourced={underResourcedPage} 
								accept={this.onGetPlasticCardClick} 
								isLoading={this.state.isLoading}
								goToLoadPage={this.goToLoadPage}
							/>
						}
					</Container>
				</Section>
			</Modal>
		);
	}
}

export default Single;
