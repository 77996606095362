import React from 'react';
import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/Button';
import Timer from 'components/Timer';
import ButtonLink from 'components/ButtonLink';
import './SendCode.scss';

const SendCodePage = ({
	textInputPlaceHolder,
	textButtonSend,
	textButtonResend,
	error,
	valueInput,
	onChange,
	textAboutSendCode,
	sendCode,
	loader,
	timeResend,
	informationResend,
	disableResend,
	onResend
}) => {
	return (
		<div className='SendCode'>
			<Input
				name='code'
				placeholder={textInputPlaceHolder}
				onChange={onChange}
				value={valueInput}
				errors={error}
			/>
			<Title title='' subtitle={textAboutSendCode} />
			<div className='SendCode__Buttons'>
				<Button
					className='SendCode__Button'
					onClick={sendCode}
					loading={loader}
					small
					form
				>
					{textButtonSend}
				</Button>
				<p className='SendCode__ResendInfo'>{informationResend}</p>
				<ButtonLink
					className='SendCode__Resend'
					onClick={onResend}
					disabled={disableResend}
					small
					form
					secondary
				>
					{disableResend && <Timer time={timeResend} />}

					{!disableResend && `${textButtonResend}`}
				</ButtonLink>
			</div>
		</div>
	);
};

export default SendCodePage;
