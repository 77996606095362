import { SET_MODALS_INFO, CHANGE_MODAL_INFO, SEPA_WINDOW_TYPE } from 'constants';

const initialState = {
  modals: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MODALS_INFO:
      return { ...state, modals: action.payload };
    case CHANGE_MODAL_INFO: 
      const updateModals = state.modals.map((modal, index) => {
        if (modal.windowType === action.payload.windowType) {
          modal.show = false;
        }
        return modal;
      })

      return {...statem, modals: updateModals};
    default:
      return state;
  }
}
