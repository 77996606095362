import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { logEvent } from '@firebase/analytics';
import { connect } from 'react-redux';
import { firebaseAnalytics } from 'helpers/analytics';
import Form from 'components/Form';
import Icon from 'components/Icon';
import InputRadio from 'components/InputRadio';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import { surveyAnswers, START_SURVEY } from 'constants'
import storage from 'services/storage';
import * as clientActions from 'actions/client';
import * as cardActions from 'actions/card';
import { getCountryUser } from 'selectors/country';
import M from './Survey.locale.json';

const Survey = ({ intl,  onSend, cards, actions, country, user }) => {
  const [currentAnswer, setAnswer] = useState({
    name:'',
    value: '',
  })
  const [CHID, setCHID] = useState(0)

  const onChange = (value, name, e) => {
    setAnswer({ name, value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      CHID,
      answer: currentAnswer.value,
      user_answer: `${CHID}|${currentAnswer.value}|${country.name}`,
    };

    logEvent(firebaseAnalytics,'how_did_you_find_us', data);
    storage.remove(START_SURVEY);
    onSend();
  }

  useEffect(() => {
    if(!cards.length) {
      actions.card.fetch();
    }
    storage.set(START_SURVEY, true);
  }, [])

  useEffect(()=> {
    if(cards.length) {
      setCHID(cards[0].cardholderId);
    }
  },[cards])

  useEffect( () => {
    if (!user.address) {
      actions.client.private.getAddress();
    }
  }, [user.address])

  const t = intl.formatMessage;

  return (
  <Wrap className='Survey'>
    <Icon className='Survey__Icon' name='survey'/>
    <Title className='Survey__Title' title={t(M.title)} />
    <Form onSubmit={onSubmit}>
      {surveyAnswers.map((item, index) =>{
        return (  
          <Form.Group key={item.value}>
            <InputRadio
              className='Survey__Checkbox'
              name={item.name}
              checked={currentAnswer.name === item.name}
              onChange={onChange}
              value={item.value}
            >
              {t({id:item.label})}
            </InputRadio>
          </Form.Group>)
        })
      }
      <div className='Survey__ButtonWrapper'>
        <Button className='Survey__Button' disabled={!currentAnswer.value} form>{t(M.send)}</Button>
      </div> 
    </Form>
  </Wrap>)
}

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		client: {
			private: bindActionCreators(clientActions.privateActions, dispatch),
		}
  },
});

const mapState = state => ({
	cards: state.card.items,
	country: getCountryUser(state),
	user: state.user
});

export default injectIntl(connect(mapState, mapDispatch)(Survey));
