/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { API } from '../../services/api/exchange-api';
import { injectIntl } from 'react-intl';

import { CookieName, CryptoUrl, FiatUrl, Method, OperationType, SERVER_URL, UserType, UserUrl } from '../../constants/exchange-const';

import CreateExchange from '../../components/CreateExchange/CreateExchange';

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import ExchangeModal from '../../components/ExchangeModal/ExchangeModal';
import SuccessExchangeModal from '../../components/SuccessExchangeModal/SuccessExchangeModal'

import './Exchange.scss';
import { useSelector } from 'react-redux';

export const BASE_CRYPTO_URL = "http://api-dev2.weststeincard.com/api/private/crypto"

const Form = ({ intl }) => {


	const uid = useSelector(({ user }) => user.id);
	const arrCard = useSelector(( {card} ) => card.items.filter(i => i.id == card.current));
	const cardholderId = arrCard[0].id
	
	const [isModal, setIsModal] = useState(null);
	const [isSuccessModal, setIsSuccessModal] = useState(false);

	const [user, setUser] = useState(UserType.UNSET);

	const [eur, setEur] = useState(``);
	const [tron, setTron] = useState(``);
	const [trx, setTrx] = useState(``);

	const [transaction, setTransaction] = useState({
		passPhrase: ``,
		amount: ``,
		transferWallet: ``,
		ID: ``
	});

	const [userInfo, setUserInfo] = useState(null);

	const [operationType, setOperationType] = useState(OperationType.CRYPTO_TO_FIAT);

	const submitFormHandler = evt => {
		evt.preventDefault();

		const options = {
			method: Method.POST,
			url: BASE_CRYPTO_URL+ `/${cardholderId}` + FiatUrl.BUY,
			data: {
				amount: +eur,
			}
		};


		switch (operationType) {
			case OperationType.FIAT_TO_CRYPTO:
				options.url =BASE_CRYPTO_URL + `/${cardholderId}` + CryptoUrl.SELL;
				options.data.amount = +tron;
				break;
			case OperationType.CRYPTO_TO_CRYPTO:
				options.data.amount = +tron;
				options.url = BASE_CRYPTO_URL+ `/${cardholderId}` + CryptoUrl.SEND;
				options.data.address = transaction.transferWallet;
				break;
			default:
				break;
		}



		// if (operationType === OperationType.CRYPTO_TO_FIAT) {
		// 	axios.request(options);
		// } else {
		// }
		API(options)
			.then(({ data }) => {

				setIsSuccessModal(true)
				if (data.response.data === `INCORRECT PASSPHRASE`) {
					setTrx(`INCORRECT PASSPHRASE`)
				} else if (data.response.data === `LOW BALANCE`) {
					setTrx(`LOW BALANCE`)
				} else {
					setTrx(`${intl.messages["exchange.success.track"]} ${data.response.data}`)
				}

			});

		setTransaction(state => ({
			...state,
			passPhrase: ``,
			amount: ``,
			transferWallet: ``
		}));
	};

	useEffect(() => {
		if (!userInfo) {
			const fetchUserInfo = async () => {
				const { data } = await API({
					method: Method.GET,
					url: BASE_CRYPTO_URL  + `/${cardholderId}` + UserUrl.USER_INFO,
				});
				setUserInfo(data.response.data);

				if (data.data !== `user not found`) {
					setIsModal(false);
				} else {
					setIsModal(true);
				}
			};
			fetchUserInfo();
		}
	}, [userInfo]);



	return (
		<Layout
			className="HistoryScreen Screen"
			sidebar
		>
			<Nav />
			<Header />

			<div className="exchange">

				{isModal === false
					&& <CreateExchange
						submitFormHandler={submitFormHandler}
						operationType={operationType}
						setOperationType={setOperationType}
						transaction={transaction}
						setTransaction={setTransaction}
						userInfo={userInfo}
						user={user}
						eur={eur}
						setEur={setEur}
						tron={tron}
						setTron={setTron}
					/>
				}

				{
					isModal === true
					&& <ExchangeModal
						setIsModal={setIsModal}

					/>

				}

				{
					isSuccessModal && trx && <SuccessExchangeModal message={trx} setModal={setIsSuccessModal} />
				}
			</div>
		</Layout>
	);
};

export default injectIntl(Form);
