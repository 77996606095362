import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { SEPA_WINDOW_TYPE, PAYMENT_FIELDS } from 'constants';
import helpers from 'helpers';
import * as paymentActions from 'actions/payment';
import * as paymentSelector from 'selectors/payment';
import PaymentSchema from 'schemas/payment';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import Modal from './BeneficiaryModal';
import { getModalInfo } from 'selectors/modals';
import * as modalsActions from 'actions/modals';
import M from './Beneficiary.locale.json';

const mapState = (state, props) => {
  const { match } = props;
  const { id } = match.params;

  return {
    payment: paymentSelector.get(state, id),
    modal: getModalInfo(state, SEPA_WINDOW_TYPE)
  };
};

const mapDispatch = dispatch => ({
  actions: {
    payment: bindActionCreators(paymentActions, dispatch),
    modal: bindActionCreators(modalsActions, dispatch)
  },
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
  state = {
    loader: false,
    warning: false,
    form: {
      beneficiary: '',
      iban: '',
      bic: '',
      amount: '',
      details: '',
    },

    errors: {
      beneficiary: [],
      iban: [],
      bic: [],
      amount: [],
      details: []
    }
  }

  componentDidMount() {
    this.props.actions.modal.getModalInfo();
  }

  componentDidUpdate(prevProps) {
    this.onUpdate(prevProps);
  }

  onUpdate(prevProps) {
    const { payment } = this.props.location;

    if (typeof payment === 'undefined' ||
      prevProps.location.payment === payment) return;

    this.setState({
      form: {
        ...this.state.form,
        iban: payment.iban,
        bic: payment.bic,
        amount: payment.amount,
        beneficiary: payment.beneficiary,
        details: payment.details,
      }
    });
  }

  onChange = (value, name) => {
    const { form, errors } = this.state;

    let messages = [];

    if (errors[name].length) {
      messages = helpers.validator.single({
        ...form,
        [name]: value,
      }, name, PaymentSchema.create);
    }

    let convert = value;

    if (PAYMENT_FIELDS.includes(name)) convert = value.toUpperCase();

    this.setState({
      form: {
        ...form,
        [name]: convert,
      },
      errors: {
        ...errors,
        [name]: messages,
      },
      loader: false,
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const { form, loader, warning } = this.state;
    const { actions, history, modal } = this.props;

    // check if form was already submitted
    if (loader) return;

    const errors = helpers.validator.all(form, PaymentSchema.create);

    // set errors and return
    if (errors) {
      this.setState({
        errors: {
          ...this.state.errors,
          ...errors,
        },
      });

      return false;
    }

    if (!modal.show) {
      this.sendData()
    } else {
      this.setState({warning: true})
    }

  }

  sendData = async () => {
    const { form, loader } = this.state;
    const { actions, history } = this.props;
    if (loader) return
    this.setState({
      loader: true,
    });

    // hard coded for now
    form.currency = 'EUR';

    const response = await actions.payment.create(form);

    const update = {
      errors: {
        ...this.state.errors,
        ...response.errors,
      },
      loader: false,
    };

    if ((response && response.errors) && response.messages) {
      update.messages = response.messages;

      this.setState(update);

      return;
    }

    // clean
    update.form = {
      beneficiary: '',
      iban: '',
      bic: '',
      amount: '',
      details: '',
    };

    this.setState(update);

    history.push(`/payments/create/${response.id}`);
  }

  closeModal = () => {
    this.setState({ warning: false });
  }

  changeBic = (notShowAnymore) => {
    if (notShowAnymore) {
      this.props.actions.modal.sendModalInfo([{ windowType: SEPA_WINDOW_TYPE }])
    }
    this.closeModal()
  }

  nextStep = (notShowAnymore) => {
    if (notShowAnymore) {
      this.props.actions.modal.sendModalInfo([{ windowType: SEPA_WINDOW_TYPE }])
    }
    this.closeModal();
    this.sendData()
  }

  render() {
    const { payment, modal } = this.props;
    const { form, errors, loader, warning } = this.state;

    const t = this.props.intl.formatMessage;
    const showModal = modal && modal.message && modal.show && warning;
    return (
      <div>
        <Title
          title={t(M.title)}
          legend={t(M.legend)}
          className="PaymentCreateScreen__Title"
        />

        <Form
          onSubmit={this.onSubmit}
        >
          <Form.Group>
            <Input
              name="beneficiary"
              placeholder={t(M.inputs.beneficiary)}
              onChange={this.onChange}
              value={form.beneficiary}
              errors={errors.beneficiary}
              autoFocus
            />
          </Form.Group>

          <Form.Group>
            <Input
              name="iban"
              placeholder={t(M.inputs.iban)}
              onChange={this.onChange}
              value={form.iban}
              errors={errors.iban}
            />
          </Form.Group>

          <Form.Group>
            <Input
              name="bic"
              placeholder={t(M.inputs.bic)}
              onChange={this.onChange}
              value={form.bic}
              errors={errors.bic}
            />
          </Form.Group>

          <Form.Group>
            <Input
              name="amount"
              placeholder={t(M.inputs.amount)}
              onChange={this.onChange}
              value={form.amount}
              unit="EUR"
              errors={errors.amount}
            />
          </Form.Group>

          <Form.Group>
            <Input
              name="details"
              placeholder={t(M.inputs.details)}
              onChange={this.onChange}
              value={form.details}
              errors={errors.details}
            />
          </Form.Group>

          <Button
            className="PaymentCreateScreen__Submit"
            loading={loader}
            small
            form
          >
            {t(M.buttons.next)}
          </Button>
        </Form>
        {showModal && <Modal modalInfo={modal} changeBic={this.changeBic} nextStep={this.nextStep} onClose={this.closeModal} />}
      </div>
    );
  }
}
