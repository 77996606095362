import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import  { timeDifferenceMinutes }  from 'helpers/timeDifference';
import Timer from 'components/Timer';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import { ArrowLeftIcon } from 'components/icons';
import * as authActions from 'actions/auth';
import * as countryActions from 'actions/country';
import helpers from 'helpers';
import { CLOSE_TWOFACTOR_AUTH } from 'constants';
import M from './Login.locale.json';
import './Login.scss';

const TwoFactorSendCode = ({ intl, actions, twoFactor, country }) => {
	const [valueCode, setValueCode] = useState('');
	const [errorsCode, setErrorsCode] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isDisableResend, setDisableResend] = useState(false);
	const [timeResend, setTimeResend] = useState(0);
	let timeout = 0;
	const t = intl.formatMessage;

	useEffect(() => {

		if (!country.length) {
			actions.country.fetch({
				entroll: true
			});
		}

		checkResendTime()

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const checkResendTime = async () => {
		const form = {
			userId: twoFactor.user.id,
			email: twoFactor.user.email,
		};
		const lastRequest = await actions.auth.getLastRequest(form);
		const lastRequestDate = lastRequest.created;
		const currentDate = new Date();
		const result = timeDifferenceMinutes(currentDate, lastRequestDate);

		if (result <= 1) {
			onDisable(60 * (1 - result));
			return false
		}
		
		onDisable();
		actions.auth.getCodeTwoFactor(form);
	};

	const onSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const errors = helpers.validator.all(
			{ code: valueCode },
			{
				code: {
					presence: { message: '^schemas.presence' }
				}
			}
		);

		if (errors) {
			setErrorsCode([...errors.code]);
			setLoading(false);
			return;
		}

		const form = {
			userId: twoFactor.user.id,
			email: twoFactor.user.email,
			code: valueCode
		};

		const response = await actions.auth.sendCodeTwoFactor(form);

		if (response && response.errors) {
			
			if (response.message === 'Invalid verification code') {
				setErrorsCode([{ id: 'schemas.two.factor.code.invalid' }]);
			} else {
				setErrorsCode([{ id: 'schemas.two.factor.code.expired' }]);
			}
			
			setLoading(false);
		}
	};

	const userPhone = useMemo(() => {
		const countryCode = country.reduce((acc, rec) => {

			if (rec.id === twoFactor.user.phoneCountry) {
				return `+${rec.phoneCode}`;
			}

			return acc;
		}, '');
		const phoneNumber = `${countryCode}${twoFactor.user.phone}`;
		return phoneNumber;
	}, [country.length]);

	const onResend = () => {

		if (!isDisableResend) {
			onDisable();
			actions.auth.getCodeTwoFactor({ 
				userId: twoFactor.user.id,
				email: twoFactor.user.email
			 });
		}

	};

	const onChange = (value, name) => {
		let messages = [];
		if (errorsCode.length) {
			messages = helpers.validator.single(
				{
					[name]: value
				},
				name,
				{
					code: {
						presence: { message: '^schemas.presence' }
					}
				}
			);
		}
		setValueCode(value);
		setErrorsCode(messages);
	};

	const onDisable = (time = 60) => {
		setTimeResend(Math.ceil(time));
		setLoading(false);
		setDisableResend(true);
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			setDisableResend(false);
			setTimeResend(0);
		}, time * 1000);
	};

	const logout = () => {
		actions.closeTwoFactor();
	};

	return (
		<div className={'TwoFactor'}>
			<div className={'TwoFactor__Title'}>
				<ArrowLeftIcon
					onClick={logout}
					className={'TwoFactor__Arrow'}
				/>
				<Title
					title={t(M.twoFactor.title)}
					legend={t(M.twoFactor.legend)}
				/>
			</div>

			<div className={'TwoFactor__form'}>
				<Form onSubmit={onSubmit}>
					<Form.Group>
						<Input
							name='code'
							placeholder={t(M.twoFactor.code)}
							onChange={onChange}
							value={valueCode}
							errors={errorsCode}
							autoFocus
						/>
					</Form.Group>
					<p className={'TwoFactor__information'}>
						{`${t(M.twoFactor.information)} ${userPhone}`}</p>
					<Button form loading={isLoading}>
						{t(M.twoFactor.login)}
					</Button>
				</Form>
				<p>{t(M.twoFactor.question)}</p>
				<ButtonLink className='SendCode__Resend' onClick={onResend}>
					{isDisableResend && <Timer time={timeResend} />}
					{!isDisableResend && `${t(M.twoFactor.resend)}`}
				</ButtonLink>
			</div>
		</div>
	);
};
const mapState = state => ({
	twoFactor: state.twoFactorAuth,
	country: state.country
});
const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
		closeTwoFactor: () => {
			dispatch({ type: CLOSE_TWOFACTOR_AUTH });
		},
		country: bindActionCreators(countryActions, dispatch)
	}
});
export default injectIntl(connect(mapState, mapDispatch)(TwoFactorSendCode));
