import { CHANGE_TWOFACTOR, NOT_SHOW_TWOFACTOR_MODAL } from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import M from "./auth.locale.json";


export const disableTwoFactor = form => async dispatch => {
	try {
		const result = await API.twoFactor.disableTwoFactor(form);

		dispatch({ type: CHANGE_TWOFACTOR });

		return result.data.response;
	} catch (err) {
		return err;
	}
};

export const enableTwoFactor = form => async dispatch => {
	try {
		const result = await API.twoFactor.enableTwoFactor(form);

		dispatch({ type: CHANGE_TWOFACTOR });

		return result.data.response;
	} catch (err) {
		return err;
	}
};

export const disableModalDisplay = () => async dispatch => {
	try {
		const result = await API.twoFactor.disableModalDisplay()

		dispatch({type: NOT_SHOW_TWOFACTOR_MODAL})

		return result;
	} catch (err) {
		return err;
	}
};
export const generateCode = () => async dispatch => {
	try {
		const result = await API.twoFactor.settingsGenerateCode();
		success(dispatch)({ title: M.success.get.title });
		return result.data.response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
};

export const getLastRequestSetting = () => async () => {
	try {
		const { data: { response } } = await API.twoFactor.getLastRequestSetting();
		return response;
	} catch (error) {
		return error;
	}
};