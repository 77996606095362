import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as cardSelector from 'selectors/card';

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import LoadSection from 'sections/Load';
import BlockingScreen from '../Blocking';

import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';

import helpers from 'helpers';
import * as userSelector from 'selectors/user';


const mapState = state => ({
	card: state.card,
	currentCard: cardSelector.getById(state, state.card.current),
	currentUser: userSelector.getUser(state),
});

@connect(mapState)
export default class Screen extends Component {
	render () {
		const { currentCard, currentUser } = this.props;

		if (currentCard && (
			['CARD_04', 'CARD_05', 'CARD_06', 'CARD_07', 'CARD_08'].includes(currentCard.product)
			|| helpers.checker.isSU(currentUser)
			)) {
			return (
				<Layout
					className="DepositScreen Screen"
					sidebar
				>
					<Nav />
					<Header />

					<LoadSection />
				</Layout>
			);
		}
		return (
			<Section className="LoadSection">
				<Container>
					<Wrap>
						<BlockingScreen />
					</Wrap>
				</Container>
			</Section>
		);
	}
}
