import Axios from 'axios';
import fetcher from 'helpers/fetcher';

export default {
	get () {
		return fetcher.get('user');
	},
	documents: {
		create (file, type) {
			const data = new FormData();
			data.append('file', file);
			return fetcher.post(`user/required-documents`, data, {
				params: { type },
				headers: { 'Content-Type': null }
			});
		},
		list () {
			return fetcher.get(`user/required-documents`);
		},
		get (id, token) {
			return fetcher.get(`open/user/required-documents/${id}?token=${token}`, {
				responseType: 'blob',
			});
		},
		remove (id) {
			return fetcher.delete(`/user/required-documents/${id}`);
		}
	},
	getLinkedCards () {
		return fetcher.get('/user/recharge-cards');
	},

	deleteLinkedCard (id) {
		return fetcher.delete(`/user/recharge-card/${id}`);
	},
	accountReviewSuccess () {
		return fetcher.post('user/account-review/success')
	},
	// sumSubVerification (id) {
	// 	return fetcher.get(`/private/crypto/${id}/sumsubtoken`)
	// },
	// getSumSubStatus (id) {
	// 	return fetcher.get(`/private/crypto/${id}/approve`)
	// },
	createUser (id) {
		return Axios.post('http://79.143.29.123:1337/api/user/reg',{sepa:'test'}, {headers: {
			uid: id,
			token: 'vWPQRlL2IL22ps3d0EW2hyBkPu9v7'
		}})
	}
	
	
};
