import {
	SHOW_UI_ALERT,
	HIDE_UI_ALERT,
	SHOW_TWOFACTOR_ALERT,
	HIDE_TWOFACTOR_ALERT,
  SHOW_SUMSUB_MODAL,
  HIDE_SUMSUB_MODAL,

} from 'constants';

export const showAlert = payload => dispatch => {
	dispatch({ type: SHOW_UI_ALERT, payload });
};

export const hideAlert = () => dispatch => {
	dispatch({ type: HIDE_UI_ALERT });
};
export const showTwoFactorAlert = () => dispatch => {
	dispatch({ type: SHOW_TWOFACTOR_ALERT });
};

export const hideTwoFactorAlert = () => dispatch => {
	dispatch({ type: HIDE_TWOFACTOR_ALERT });
};

export const showSumSubModal = payload => dispatch => {
	dispatch({ type: SHOW_SUMSUB_MODAL, payload });
};

export const hideSumSubModal = () => dispatch => {
	dispatch({ type: HIDE_SUMSUB_MODAL });
};

export const checkErrorAmount = (dispatch, errors) => {
  if (errors.amount) {
    dispatch({
      type: SHOW_UI_ALERT, 
      payload: {
        title: 'screens.setting.setting.document.proof.note',
        text: errors.amount[0].id,
      }
    });
  }
};
