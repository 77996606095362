import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Title from 'components/Title';
import Button from 'components/Button';
import M from './TwoFactorAuthentication.locale.json';
import './QuestionModal.scss';

const WarningModal = ({ t, goToNextSection }) => {

    return (
        <React.Fragment>
            <Title
                title={t(M.information.titleWarning)}
                subtitle={t(M.information.warning)}
                small
                className='SingleWarning-text'
            />
            <div className='SingleWarning-controls'>
                <Button
                    onClick={goToNextSection}
                    small
                    className='SingleWarning-button'
                >
                    <FormattedMessage id='button.simple.ok' />
                </Button>
            </div>
        </React.Fragment>
    )
}

WarningModal.propTypes = {
    goToNextSection: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default WarningModal;
