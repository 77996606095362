import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import snsWebSdk from '@sumsub/websdk';

import * as uiActions from 'actions/ui';

import API from 'services/api';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Button from 'components/Button';
import Icon from 'components/Icon';

import './UiAlert.scss';
import { getSumSubVerification } from '../../actions/auth';

const mapState = state => ({
	user: state.user,
    ui: state.ui.sumSubModal
});
const mapDispatch = dispatch => ({
	actions: {
		ui: bindActionCreators(uiActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class SumSubModal extends PureComponent {
    componentDidMount() {
		this.props.ui && this.getToken();
	}
	onSkip = () => {
		const { actions } = this.props;
		actions.ui.hideSumSubModal();
	};


    launchWebSdk = (accessToken) => {
        let snsWebSdkInstance = snsWebSdk.init(
                accessToken,
                // token update callback, must return Promise
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                () => getNewAccessToken()
            )
            .withConf({
                lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true})
            // see below what kind of messages WebSDK generates
            .on('idCheck.stepCompleted', (payload) => {
                console.log('stepCompleted', payload)
            })
            .on('idCheck.onError', (error) => {
                console.log('onError', error)
            })
            .build();
      
        // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        snsWebSdkInstance.launch('#sumsub-websdk-container')
      };
      
    getNewAccessToken = () =>  {
      return Promise.resolve("sbx:F0lWS7ga8ylbuQuQB4FbXwmc")// get a new token from your backend
      };
    
    getToken = async () => {
		if (this.props.user.id) {
			await API.user.sumSubVerification(this.props.user.id).then(res => { // find api
        if (res.data.type === 'OK') {
          this.launchWebSdk(res.data.data)
        }
      })
		}
      
    };

	render () {
		const { ui: { showSumSubModal }, intl } = this.props;
		const t = intl.formatMessage;



		if (!showSumSubModal) {
			return null;
		}

		return (
			<Modal>
				<Section>
					<Container medium>
						<Wrap className="ui-modal">
							<Icon
								className="ui-modal__close" 
								onClick={this.onSkip} 
								name="close-small"
							/>
							
                            <div id="sumsub-websdk-container"></div>
							<div className='btn'>
							    <Button
									onClick={this.onSkip}
									small
									className="ui-modal__button"
									>
									<FormattedMessage id="ui.alert.button.close" />
								</Button>
							</div>		
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}