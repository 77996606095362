import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedDate, injectIntl } from 'react-intl';
import intlTypes from 'types/intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PUSH_NOTIFICATION_TYPE } from 'constants/notifications';
import * as notificationsActions from 'actions/notifications';
import { getList } from 'selectors/card';
import { notificationsActionsTypes } from 'types/actions';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import {
	NOTIFICATIONS_TYPE_SUCCESS,
	NOTIFICATIONS_TYPE_WARNING,
	NOTIFICATIONS_TYPE_ERROR
} from 'constants';

import {
	SuccessIcon,
	WarningIcon,
	ErrorIcon,
	ExpandIcon
} from 'components/icons';

import NotificationActions from '../NotificationActions/NotificationActions';

import M from '../../NotificationPopup.locale.json';

import './NotificationItem.scss';

const NotificationItem = ({
	notification,
	intl,
	actions,
	history,
	closeNotifications,
	cards,
	scrollContainerToBottom,
	openNotificationModal,
	alreadyOrderedCard
}) => {
	const t = intl.formatMessage;
	const { type, pushNotificationType, message, created, id } = notification;

	const [isOpened, setIsOpened] = useState(false);

	const toggleOpen = useCallback(e => {
		if (
			!e.target.closest('.notification-info__mark') &&
			!e.target.closest('.notification-info__actions')
		) {
			setIsOpened(state => !state);
		}
	}, []);

	const onActionSelect = item => {
		switch (item.value) {
			case 'renew': {
				const card = cards.find(card =>
					message.includes(card.cardNumber)
				);
				const path = card ? `/cards/${card.id}/reissue` : '/cards';
				closeNotifications();
				history.push(path, { modal: !!card });
				break;
			}

			case 'mark': {
				actions.notifications.markAsRead([notification]);
				break
			}

			case 'alreadyCard': {
				alreadyOrderedCard(id);
				break;
			}

			case 'notOrderCard': {
				openNotificationModal(id, item.value);
				break;
			}
			case 'orderCard': {
				openNotificationModal(id, item.value);
				actions.notifications.markAsRead([notification]);
				break;
			}
		}
	};

	const notificationsDropdownConfig = {
		[PUSH_NOTIFICATION_TYPE.TIANDE]: [
			{
				value: 'orderCard',
				title: t(M.actions.orderCard)
			},
			{
				value: 'mark',
				title: t(M.actions.close)
			},
			{
				value: 'alreadyCard',
				title: t(M.actions.alreadyCard)
			},
			{
				value: 'notOrderCard',
				title: t(M.actions.notOrderCard)
			}
		],

		[PUSH_NOTIFICATION_TYPE.PRIVATE]: [
			{
				value: 'renew',
				title: t(M.actions.renew)
			},
			{
				value: 'mark',
				title: t(M.actions.mark)
			}
		]
	};

	const iconNotification = {
		[NOTIFICATIONS_TYPE_SUCCESS]: SuccessIcon,
		[NOTIFICATIONS_TYPE_WARNING]: WarningIcon,
		[NOTIFICATIONS_TYPE_ERROR]: ErrorIcon,
	}
    const Icon = React.createElement(iconNotification[type], { className: 'notification__icon' }, null)

	return (
		<div
			role='button'
			tabIndex={0}
			className={cn('notification', { 'notification--opened': isOpened })}
			onClick={toggleOpen}
		>
			<div className='notification-content'>
				{Icon}
				<span
					className={cn('notification__text', {
						'notification__text--opened': isOpened
					})}
				>
					{message}
				</span>

				<ExpandIcon
					className={cn('notification__expand-icon', {
						'notification__expand-icon--opened': isOpened
					})}
				/>
			</div>
			<div className='notification-info'>
				{created && (
					<span className='notification-info__date'>
						<FormattedDate value={new Date(created)} />
					</span>
				)}
				{isOpened && (
					pushNotificationType === PUSH_NOTIFICATION_TYPE.SIMPLE
						? <span
							tabIndex={0}
							role="button"
							onClick={() => actions.notifications.markAsRead([notification])}
							className={cn('ButtonLink', 'notification-info__mark')}
						>
							{t(M.buttons.mark.current)}
						</span>
						: <NotificationActions
							scrollContainerToBottom={scrollContainerToBottom}
							dropdown={notificationsDropdownConfig[pushNotificationType]}
							onSelect={onActionSelect}
						/>
				)}
			</div>
		</div>
	);
};

const mapDispatch = dispatch => ({
	actions: {
		notifications: bindActionCreators(notificationsActions, dispatch)
	}
});

const mapState = (state, props) => ({
	cards: getList(state)
});

NotificationItem.propTypes = {
	notification: PropTypes.shape({
		id: PropTypes.number,
		message: PropTypes.string,
		pushNotificationType: PropTypes.string,
		created: PropTypes.string,
		type: PropTypes.string
	}),
	actions: PropTypes.shape({
		notifications: notificationsActionsTypes
	}),
	intl: intlTypes.isRequired,
	history: historyTypes.isRequired,
	closeNotifications: PropTypes.func,
	cards: PropTypes.arrayOf(currentCardTypes),
	scrollContainerToBottom: PropTypes.func,
	openNotificationModal: PropTypes.func,
	alreadyOrderedCard: PropTypes.func
};

export default compose(
	withRouter,
	injectIntl,
	connect(mapState, mapDispatch)
)(NotificationItem);
