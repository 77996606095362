import axios from "axios";
import { SERVER_URL } from "../../constants/exchange-const";


const REQUEST_TIMEOUT = 50000;

export const createAPI = () => {

  const api = axios.create({
    timeout: REQUEST_TIMEOUT,
    withCredentials: false,
    baseURL: SERVER_URL
  });

  const requestHandler = request => {
    request.headers.authorization = localStorage.getItem(`session`).slice(1, localStorage.getItem(`session`).length - 1);
    return request;
  };

  api.interceptors.request.use(request => requestHandler(request));

  return api;
};

export const API = createAPI();