import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Title from 'components/Title';
import Wrap from 'components/Wrap';
import Icon from 'components/Icon';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';

import M from './Success.locale.json';
import intlTypes from 'types/intl';
import historyTypes from 'types/history';


@withRouter
@injectIntl
export default class Success extends React.PureComponent {
	static propTypes = {
		deliveryDate: PropTypes.string,
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
	};

	componentDidMount () {
		triggerEvent(GTMEvent.congratulationsScreen, '/clients/create/private');
	}

	onDashboard = () => this.props.history.push('/');

	onVerify = () => this.props.history.push('/settings/document');

	render () {
		const { intl } = this.props;
		const t = intl.formatMessage;

		return (
			<Wrap>
				<div className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Success">
					<Icon name="success" />
				</div>
				<Title title={t(M.title)} />
				<p>{t(M.messages.text)}</p>
				<Button form small onClick={this.onVerify}>
					{t(M.buttons.verify)}
				</Button>
				<ButtonLink className="PrivateScreenSuccess__actions-skip" onClick={this.onDashboard}>
					{t(M.buttons.skip)}
				</ButtonLink>
			</Wrap>
		);
	}
}
