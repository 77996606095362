import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import LoaderGlobal from 'components/LoaderGlobal';
import { userStatus, clientStatus } from 'constants/common';
import * as clientSelector from 'selectors/client';
import { getInitialRegulatoryDataCollection } from 'selectors/card';
import * as clientActions from 'actions/client';
import Nav from 'sections/Nav';
import Success from './Success';
import Failed from './Failed';
import Retry from './Retry';
import './Private.scss';
import AccountReview from '../../AccountReview';
import Survey from './Survey';

const mapState = state => ({
	user: state.user,
	client: clientSelector.getCurrentClient(state),
	regulatoryData: getInitialRegulatoryDataCollection(state),
});

const mapDispatch = dispatch => ({
	actions: {
		client: {
			private: bindActionCreators(clientActions.privateActions, dispatch),
		},
	},
});

@connect(mapState, mapDispatch)
export default class Private extends React.PureComponent {
	state = {
		loader: false,
		loaderGlobal: false,
		deliveryDate: '',
		manualVerification: false,
		messages: [],
		isOpenSurvey: true,
	}

	componentDidMount () {
		this.onSend();
	}

	onSend = async () => {
		const { actions, client, user, regulatoryData } = this.props;
		const { loader, loaderGlobal } = this.state;
		const update = { loader: false, messages: [] };

		if (loader) return;
		if (loaderGlobal) return;

		this.setState({ loader: true, loaderGlobal: true });

		if (client.status !== clientStatus.INITIATED) {
			this.setState({ loaderGlobal: false, ...update });
		} else {
			if (user.phoneVerified && user.verified && regulatoryData) {
				const response = await actions.client.private.create();

				if(response.errors && response.errors['ofac 050'] ) {
					this.setState({manualVerification: true})
				}

				if (response && response.messages) update.messages = response.messages;
				if (response && response.cardholderIds) {
					update.deliveryDate = response.cardholderIds[0].deliveryDate;
				}
			}

			this.setState({ loaderGlobal: false, ...update });
		}
	}

  sendSurvey = () => {
    this.setState({isOpenSurvey: false});
  }
	render () {
		const { loader, loaderGlobal, deliveryDate, manualVerification, isOpenSurvey } = this.state;
		const { user, client } = this.props;
    
		return (
			<Layout className="Screen">
				<Nav logo />
				<Section className='private'>
					<Container medium>
						<LoaderGlobal loading={loaderGlobal} />
						{client.status === clientStatus.ACTIVE && !isOpenSurvey && <Success deliveryDate={deliveryDate} />}

						{client.status === clientStatus.ACTIVE && isOpenSurvey && <Survey onSend={this.sendSurvey} />}

						{user.status === userStatus.FRAUDULENT && <Failed />}

						{client.status === clientStatus.INITIATED && !loaderGlobal && !manualVerification && (<Retry onRetry={this.onSend} loader={loader} />)}
						
						{manualVerification && (<AccountReview />)}
					</Container>
				</Section>
			</Layout>
		);
	}
}
