import { useCallback, useEffect } from "react";

export const useEscHandler = (setFn) => {
  const escKeyDownHandler = useCallback((evt) => {
    if (evt.key === `Escape` || evt.key === `Esc`) {
      evt.preventDefault();
      setFn(false);
      document.removeEventListener(`keydown`, escKeyDownHandler);
    }
  }, [setFn])


  const closeHandler = () => {
    setFn(false);
    document.removeEventListener(`keydown`, escKeyDownHandler);
  }

  useEffect(() => {
    document.addEventListener(`keydown`, (evt) => escKeyDownHandler(evt));
    return document.removeEventListener(`keydown`, escKeyDownHandler);
  }, [escKeyDownHandler]);

  return [closeHandler];
}