/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { API } from "../../services/api/exchange-api";
import { InfoUrl, Method, OperationType } from "../../constants/exchange-const";
import { injectIntl } from "react-intl";

import CreateOperation from "../CreateOperation/CreateOperation";
import OperationTypes from "../OperationTypes/OperationTypes";
import UserInfo from "../UserInfo";
import BuyModal from "../BuyModal/BuyModal";
import SellModal from "../SellModal/SellModal";

import { useSelector } from "react-redux";

import "./CreateExchange.scss";
// import { TradeType } from '../../../../../weststein-admin-client/src/const';
import { TradeType } from "../../constants/exchange-const";
import { BASE_CRYPTO_URL } from "../../screens/Exchange/Exchange";
import SendModal from "../SendModal/SendModal";

const Create = ({
	submitFormHandler,

	operationType,
	setOperationType,
	transaction,
	setTransaction,
	userInfo,
	user,
	eur,
	setEur,
	tron,
	setTron,
	intl
}) => {
	const directionRef = useRef(TradeType.EUR_USDT);

	const arrCard = useSelector(({ card }) =>
		card.items.filter(i => i.id == card.current)
	);
	const cardholderId = arrCard[0].id;

	const [modal, setModal] = useState(false);
	const [tradeInfo, setTradeInfo] = useState({
		course_from: ``,
		course_to: ``,
		fee: ``,
		max_sum: ``,
		min_sum: ``
	});

	useEffect(() => {
		const fiveMinutes = 300000;
		const interval = () =>
			setInterval(() => {
				const direction = directionRef.current;

				API({
					method: Method.GET,
					url:
						BASE_CRYPTO_URL +
						`/${cardholderId}` +
						InfoUrl.TRADE_INFO,
					headers: {
						direction
					}
				}).then(({ data }) => setTradeInfo(data.response.data));
			}, fiveMinutes);

		interval();

		return clearInterval(interval);
	}, []);

	useEffect(() => {
		API({
			method: Method.GET,
			url: BASE_CRYPTO_URL + `/${cardholderId}` + InfoUrl.TRADE_INFO,
			headers: {
				direction: `EUR-USDT`
			}
		}).then(({ data }) => setTradeInfo(data.response.data));
	}, []);

	if (!tradeInfo) {
		return <div>{intl.messages["exchange.type.loading..."]}</div>;
	}

	const getModal = () => {
		switch (modal) {
			case OperationType.CRYPTO_TO_CRYPTO:
				return (
					<SendModal
						eur={eur}
						tron={tron}
						transaction = {transaction}
						setModal={setModal}
						submitFormHandler={submitFormHandler}
					/>
				);
			case OperationType.CRYPTO_TO_FIAT:
				return (
					<BuyModal
						eur={eur}
						tron={tron}
						setModal={setModal}
						submitFormHandler={submitFormHandler}
					/>
				);
			case OperationType.FIAT_TO_CRYPTO:
				return (
					<SellModal
						eur={eur}
						tron={tron}
						setModal={setModal}
						submitFormHandler={submitFormHandler}
					/>
				);
			default:
				return null;
		}
	};

	const getOperation = () => {
		switch (operationType) {
			case OperationType.CRYPTO_TO_CRYPTO:
				return intl.messages["exchange.type.send"];
			case OperationType.CRYPTO_TO_FIAT:
				return intl.messages["exchange.type.buy"];
			case OperationType.FIAT_TO_CRYPTO:
				return intl.messages["exchange.type.sell"];
			default:
				throw new Error();
		}
	};

	return (
		<form onSubmit={submitFormHandler} className="create-exchange">
			<UserInfo userInfo={userInfo} />
			<div className="create__form">
				<OperationTypes
					operationType={operationType}
					setOperationType={setOperationType}
					userInfo={userInfo}
					setTradeInfo={setTradeInfo}
					setEur={setEur}
					setTron={setTron}
					directionRef={directionRef}
				/>
				<CreateOperation
					transaction={transaction}
					setTransaction={setTransaction}
					operationType={operationType}
					tradeInfo={tradeInfo}
					user={user}
					userInfo={userInfo}
					eur={eur}
					setEur={setEur}
					tron={tron}
					setTron={setTron}
				/>
				<button
					onClick={() => setModal(operationType)}
					type="button"
					// disabled={!transaction.passPhrase && userInfo === `user not found`}
					// disabled={userInfo === `user not found`}
					className="exchange__button"
				>
					{getOperation()}
				</button>
			</div>

			{getModal()}
		</form>
	);
};

export default injectIntl(Create);
