/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { OperationType } from "../../constants/exchange-const";
import { injectIntl } from "react-intl";

import "./CreateOperation.scss";
import CustomModal from "../CustomModal/CustomModal";

const CreateOperation = ({
	transaction,
	setTransaction,
	operationType,
	tradeInfo,
	userInfo,
	eur,
	setEur,
	tron,
	setTron,
	intl
}) => {
	const [isWarningModal, setIsWarningModal] = useState(false);
	const { fee, max_sum, min_sum, course } = tradeInfo;

	const { transferWallet, passPhrase } = transaction;

	const { balance_usdt } = userInfo;

	const regex = /./g;

	const getPassphraseValue = () => {
		return (
			passPhrase.slice(0, passPhrase.length - 1).replace(regex, `*`) +
			passPhrase.slice(-1)
		);
	};

	// const passphraseBlock = (
	// 	<label className="create-operation__label create-operation__label--passphrase">
	// 		<h4>
	// 			{intl.messages["exchange.create_exchange.think_passphrase"]}
	// 		</h4>
	// 		<input
	// 			value={getPassphraseValue()}
	// 			onKeyDown={evt => {
	// 				if (
	// 					evt.target.value.length === 1 &&
	// 					evt.key === `Backspace`
	// 				) {
	// 					setTransaction(state => ({
	// 						...state,
	// 						passPhrase: ``
	// 					}));
	// 				}
	// 			}}
	// 			onChange={evt => {
	// 				const {
	// 					target: { value }
	// 				} = evt;

	// 				if (
	// 					value.length === 1 &&
	// 					operationType === OperationType.CRYPTO_TO_FIAT
	// 				) {
	// 					setIsWarningModal(true);
	// 				}

	// 				let updPassphase;
	// 				if (value.slice(-1) === `*`) {
	// 					updPassphase = passPhrase.slice(0, -1);
	// 				} else if (value.slice(-1) !== `*`) {
	// 					updPassphase = passPhrase.concat(value.slice(-1));
	// 				}

	// 				setTransaction(state => ({
	// 					...state,
	// 					passPhrase: updPassphase
	// 				}));
	// 			}}
	// 			required
	// 			type="text"
	// 			placeholder="passphrase"
	// 		/>
	// 		<i>
	// 			{intl.messages["exchange.create_exchange.remember_passphrase"]}
	// 		</i>
	// 	</label>
	// );

	const getPassphrase = () => {
		if (
			userInfo === `user not found` ||
			operationType !== OperationType.CRYPTO_TO_FIAT
		) {
			return passphraseBlock;
		}
	};

	const getMaxEurSum = () => {
		switch (operationType) {
			case OperationType.CRYPTO_TO_CRYPTO:
				return balance_usdt;
			case OperationType.FIAT_TO_CRYPTO:
				return balance_usdt < max_sum
					? (balance_usdt / course).toFixed(2)
					: (max_sum / course).toFixed(2);
			case OperationType.CRYPTO_TO_FIAT:
				return max_sum;
			default:
				throw new Error();
		}
	};

	const getMinEurSum = () => {
		switch (operationType) {
			case OperationType.FIAT_TO_CRYPTO:
				return (min_sum / course).toFixed(2);
			case OperationType.CRYPTO_TO_FIAT:
				return min_sum;
			default:
				throw new Error();
		}
	};

	const getTronSum = () => {
		switch (operationType) {
			case OperationType.CRYPTO_TO_CRYPTO:
				return balance_usdt;
			case OperationType.FIAT_TO_CRYPTO:
				return balance_usdt < max_sum ? balance_usdt : max_sum;
			case OperationType.CRYPTO_TO_FIAT:
				return (max_sum * course).toFixed(2);
			default:
				throw new Error();
		}
	};

	const getMinUsdtSum = () => {
		switch (operationType) {
			case OperationType.CRYPTO_TO_CRYPTO:
				return min_sum;
			case OperationType.FIAT_TO_CRYPTO:
				return min_sum;
			case OperationType.CRYPTO_TO_FIAT:
				return (min_sum * course).toFixed(2);
			default:
				throw new Error();
		}
	};

	const eurHandler = ({ target }) => {
		const { value } = target;

		switch (value) {
			case ``:
				setEur(``);
				setTron(``);
				break;
			default:
				setEur(value);
				setTron((+value * course).toFixed(2));
				break;
		}
	};

	const tronHandler = ({ target }) => {
		const { value } = target;

		switch (value) {
			case ``:
				setEur(``);
				setTron(``);
				break;
			default:
				setTron(+value);
				setEur((+value / course).toFixed(2));
				break;
		}
	};
	// при продаже max sum = balance_usdt у user

	const getCurrenciesPosition = () => {
		if (operationType === OperationType.FIAT_TO_CRYPTO) {
			return `column-reverse`;
		} else {
			return `column`;
		}
	};

	return (
		<div className="create-operation">
			{operationType !== OperationType.CRYPTO_TO_CRYPTO && (
				<label className="create-operation__label">
					<h4>{intl.messages["exchange.create_exchange.course"]}</h4>
					<span>{course}</span>
				</label>
			)}

			<div
				className="create-operation__currencies"
				style={{ flexDirection: getCurrenciesPosition() }}
			>
				{operationType !== OperationType.CRYPTO_TO_CRYPTO && (
					<label className="create-operation__label">
						<h4>{intl.messages["exchange.create_exchange.eur"]}</h4>
						<input
							required
							value={eur}
							min={getMinEurSum()}
							max={getMaxEurSum()}
							placeholder={`${getMinEurSum()} - ${getMaxEurSum()}`}
							onChange={eurHandler}
							step="0.01"
							type="number"
						/>
					</label>
				)}

				<label
					className="create-operation__label"
					style={{
						gridRow: `${
							operationType === OperationType.FIAT_TO_CRYPTO
								? `3/4`
								: ``
						}`
					}}
				>
					<h4>{intl.messages["exchange.create_exchange.usdt"]}</h4>
					<input
						required
						value={tron}
						min={getMinUsdtSum()}
						max={getTronSum()}
						placeholder={`${getMinUsdtSum()} - ${getTronSum()}`}
						onChange={tronHandler}
						step="0.01"
						type="number"
					/>
				</label>
			</div>

			{operationType === OperationType.CRYPTO_TO_CRYPTO ? (
				<label className="create-operation__label">
					<h4>
						{
							intl.messages[
								"exchange.create_exchange.transfer_wallet"
							]
						}
					</h4>
					<input
						value={transferWallet}
						onChange={({ target: { value } }) =>
							setTransaction(state => ({
								...state,
								transferWallet: value
							}))
						}
						type="text"
					/>
				</label>
			) : null}

			{operationType !== OperationType.CRYPTO_TO_CRYPTO && (
				<label className="create-operation__label">
					<h4>{intl.messages["exchange.create_exchange.fee"]}</h4>
					<span>{fee}%</span>
				</label>
			)}

			{/* {getPassphrase()} */}

			{isWarningModal && (
				<CustomModal
					setIsModal={setIsWarningModal}
					modalText={intl.messages["exchange.warning.modal"]}
				/>
			)}
		</div>
	);
};

export default injectIntl(CreateOperation);
