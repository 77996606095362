import {
	SHOW_UI_ALERT,
	HIDE_UI_ALERT,
	SHOW_TWOFACTOR_ALERT,
	HIDE_TWOFACTOR_ALERT,
	SHOW_SUMSUB_MODAL,
  HIDE_SUMSUB_MODAL,
} from 'constants';

const initial = {
	alert : {
		showUiAlert: false,
		title: '',
		subtitle: '',
		text: '',
	},
	twoFactor: {
		showTwoFactorAlert:false
	},
	sumSubModal: {
		showSumSubModal:false
	}
};

export default (state = initial, action) => {
	switch (action.type) {
		case SHOW_UI_ALERT:
			return { 
				...state, 
				alert: {
					...state.alert,
					showUiAlert: true,
					...action.payload
				}
			};
		case HIDE_UI_ALERT:
			return { ...state, alert: { ...initial.alert } };
		case SHOW_TWOFACTOR_ALERT: 
			return {
				...state, 
				twoFactor: {
					...state.twoFactor,
					showTwoFactorAlert:true
				}
			};
		case HIDE_TWOFACTOR_ALERT: 
			return {
				...state, 
				twoFactor: {
					...state.twoFactor,
					showTwoFactorAlert:false
				}
			};
			case SHOW_SUMSUB_MODAL: 
			return {
				...state, 
				sumSubModal: {
					...state.sumSubModal,
					showSumSubModal:action.payload
				}
			};
		case HIDE_SUMSUB_MODAL: 
			return {
				...state, 
				sumSubModal: {
					...state.sumSubModal,
					showSumSubModal:false
				}
			};
		default:
			return state;
	}
};
