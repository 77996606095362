import fetcher from 'helpers/fetcher';

export default {
	getLink (cardId) {
		return fetcher.get(`user/UploadDocuments/uuid/${cardId}?isWeb=true`);
	},
	startVerification(data) {
		return fetcher.post('jumio/jumioVerificationStart', data);
	},
	getVerificationStatus (cardholderId) {
		return fetcher.get(`jumio/getVerificationStatus?cardholderId=${cardholderId}`);
	}
};
