import React, { useEffect, useState } from "react";

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';

import { API } from "../../utils/api-service";
import NotificationItem from "../../components/NotificationItem/NotificationItem.jsx";

import './notifications.scss'
import NotificationsFilter from "../../components/NotificationsFilter/NotificationsFilter.jsx";
import { useDispatch } from "react-redux";
import { NotifyActionType } from "../../reducers/notify";
import Icon from "../../components/Icon";
import axios from "axios";

import BASE_URL from '../../../../env/development';

const Notifications = () => {
	const dispatch = useDispatch();

	const [isFilter, setIsFilter] = useState(false);
	const [notifications, setNotifications] = useState(null);

	const [applyFilter, setApplyFilter] = useState(false);


	const [eventType, setEventType] = useState(``);
	const [dateFrom, setDateFrom] = useState(``);
	const [dateTo, setDateTo] = useState(``);
	// const [offset, setoffset] = useState(``);
	// const [limit, setlimit] = useState(``);

	// url: `/`,
	useEffect(() => {

		const fetchNotifications = async () => {
			const eType = eventType ? `&eventType=${eventType}` : ``
			const from = dateFrom ? `&dateFrom=${dateFrom}` : ``
			const to = dateTo ? `&dateTo=${dateTo}` : ``

			// const res = await API({
			const res = await axios.request({
				method: `get`,
				url: `${BASE_URL.api}notification/get?limit=50${eType}${from}${to}`,
				headers: {
					Authorization: localStorage.getItem(`session`).slice(1, localStorage.getItem(`session`).length - 1)
				}
			})

			const {data: {response}}  = res;


			setNotifications(response.notifications)

		}

		fetchNotifications()
	}, [applyFilter])


	return (
		<Layout
			className="HistoryScreen Screen"
			sidebar
		>
			<Nav />

			<Header />
			<div className="notifications-screen">
				{
					!isFilter &&

					<button
						onClick={() => setIsFilter(!isFilter)}
						className="filter-button">
						<Icon name="filter-noty" />
					</button>
				}
				{
					isFilter &&
					<NotificationsFilter
						eventType={eventType}
						dateFrom={dateFrom}
						dateTo={dateTo}
						applyFilter={applyFilter}
						setEventType={setEventType}
						setDateFrom={setDateFrom}
						setDateTo={setDateTo}
						setApplyFilter={setApplyFilter}
						setIsFilter={setIsFilter}
					/>
				}

				{
					!isFilter &&

					<div className="notifications__list">
						{
							notifications
								? notifications.map((item, idx) => {

									return (
										<NotificationItem key={idx} item={item} />
									)
								})
								: null
						}
					</div>
				}
			</div>
		</Layout>
	)
}

export default Notifications;