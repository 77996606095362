import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import  { timeDifferenceMinutes }  from 'helpers/timeDifference';
import helpers from 'helpers';

import Phone from 'schemas/setting/phone';

import * as countrySelector from 'selectors/country';
import * as countryActions from 'actions/country';
import * as phoneActions from 'actions/phone';
import * as applicationActions from 'actions/applications';

import Tab from 'components/Tab';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import Timer from 'components/Timer';
import ButtonLink from 'components/ButtonLink'
import BlockingScreen from '../../Blocking';
import tracker from 'helpers/tracker';
import { validatePhone } from 'helpers/validator';

import { SuccessIcon } from 'components/icons'
import { ArrowLeftIcon } from 'components/icons'

import M from './Phone.locale.json';


const mapState = (state, props) => ({
	user: state.user,
	country: countrySelector.getEnrollCountries(state, props, M.countries),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props, M.countries),
	cards: state.card.items
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		phone: bindActionCreators(phoneActions, dispatch),
		applications: bindActionCreators(applicationActions, dispatch)
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disableResend: false,
			loader: false,
			current: 0,
			formId: 0,
			timeResend: 60,
			successfullChange: false,
			form: {
				phoneCurrent: (props.user.phone ? props.user.phone : ''),
				phone: '',
				country: '',
				phoneCountry: '',
				token: '',
				code: '',
			},
			errors: {
				phoneCurrent: [],
				phone: [],
				phoneCountry: [],
				token: [],
				code: []
			}
		};
	}

	componentDidMount() {
		this.onMount();
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	onMount = async () => {
		const { actions, country } = this.props;
		if (!country.length) {
			actions.country.fetch({
				entroll: true
			});
		}

		const response = await actions.phone.getLastRequest();

		if(response === null) {
			return false
	  	}

		// We check for existing phone requests to change tab	
			if (response.type === 'PHONE' && response.status === 'REQUESTED') {
				this.setState({
					current: 2,
					formId: response.id,
					tokenCreated: response.tokenCreated,
					form: {
						phone: response.property1,
						phoneCountry: response.property2

					}
				});
	
				this.checkResendTime();
			}

	}

	toFirstPage = () => {
		clearTimeout(this.timeout)
		const { history, user } = this.props

		this.timeout = setTimeout(() => {
			
			this.setState({
				current: 0,
				successfullChange: false,
				form:{
					phoneCurrent: (user.phone ? user.phone : ''),
				}
			})
			history.push('/')
		}, 10 * 1000)
	}

	onClick = async () => {
		const { actions, user } = this.props;
		const { form, current, formId, loader } = this.state;
		switch (current) {
			case 0:
				this.setState({ current: 1 });
				break;
			case 1:

				if (this.state.errors.phone.length || loader) return;

				if (user.phone === form.phone && user.phoneCountry === form.phoneCountry) {
					const error = M.errors.samePhone
					this.setState({
						errors: {
							...this.state.errors,
							phone: [{ id: error.id, variables: { attribute: 'phone' } }]
						}
					});
					return;
				}

				const errors = helpers.validator.all(form, Phone.stepone);

				if (errors) {
					this.setState({
						errors: {
							...this.state.errors,
							...errors,
						},
					});

					return;
				}

				this.setState({
					loader: true,
				});
				const response = await actions.phone.change(form);

				if (response && response.errors) {
					const update = {
						errors: {
							...this.state.errors,
							...response.errors,
						},
						loader: false,
					};

					if (response.messages) update.messages = response.messages;

					this.setState(update);

					return false;
				}
				this.setState({
					loader: false,
				});
				const responseResend = await actions.phone.resend(response.id);
				this.setState({
					formId: responseResend.id,
					tokenCreated: responseResend.tokenCreated,
					current: 2,
					disableResend: false,
				})
				this.onDisable();

				break;
			case 2: {

				if ( loader ) return;

				const errors = helpers.validator.all(form, Phone.steptwo);

				if (errors) {
					this.setState({ errors: { ...this.state.errors, ...errors } });

					tracker.onError();
					return;
				}

				this.setState({ loader: true });

				const response = await actions.phone.confirm(formId, form.code);

				if (response && response.errors) {
					const update = {
						errors: { ...this.state.errors, ...response.errors },
						loader: false,
					};

					if (response.messages) update.messages = response.messages;
					this.setState(update);
					return false;
				}

				if (response.type === 'PHONE' && response.status === 'GRANTED') {
					this.setState({
						successfullChange: true,
					});
				}	

				user.phone = form.phone;
				user.phoneCountry = form.phoneCountry;
				this.toFirstPage()

				break
			}
		}
	}

	onDisable = (time = 60) => {

		clearTimeout(this.timeout)
		this.setState({
			disableResend: true,
			timeResend: Math.ceil(time),
		});

		this.timeout = setTimeout(() => {
			this.setState({
				disableResend: false,
				timeResend: 0,
			});
		}, time * 1000);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Phone.stepone);
		}

		if (name === 'phone') {
			const validation = validatePhone(value);

			if (validation) {
				messages.unshift(validation);
			}
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
		});
	};
	
	checkResendTime = () => {
		const lastRequestDate = this.state.tokenCreated;
		const currentDate = new Date();
		const result = timeDifferenceMinutes(currentDate, lastRequestDate);

		if (result <= 1) {
			this.onDisable(60 * (1 - result));
			return false;
		}

		return true;
	}

	onResend = async () => {
		const resend = this.checkResendTime();

		if (!resend) {
			return;
		}

		const { actions } = this.props;
		const { disableResend } = this.state;

		if ( disableResend ) return;

		const response = await actions.phone.resend(this.state.formId);
		this.setState({
			formId: response.id,
			tokenCreated: response.tokenCreated,
		})
		this.onDisable();
	}

	onChangeSecondStep = (value, name) => {
		const { form, errors } = this.state;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Phone.steptwo);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
		});
	};

	toBack = () => {

		if (this.state.current > 0 && !this.state.loader) {
			const current = this.state.current - 1;
			this.setState({
				current
			});
		}
	}

	findCodeCountry = (currentCountry) => {
		const { phoneCountry } = this.props;
		const codeCountry = phoneCountry.filter(list => list.value == currentCountry);
		return codeCountry[0].title;
	}

	render() {
		const { user, phoneCountry } = this.props;
		const { form, errors, current, disableResend, loader, successfullChange } = this.state;
		const showArrow = current > 0;
		let phoneNumber = '';

		if (phoneCountry.length) {

			if (current) {

				if (form.phoneCountry) {
					const codeNumber = this.findCodeCountry(form.phoneCountry);
					phoneNumber = `${codeNumber}${form.phone}`;
				}
				
			} else {
				const codeNumber = this.findCodeCountry(user.phoneCountry);
				phoneNumber = `${codeNumber}${user.phone}`;
			}

		}

		const t = this.props.intl.formatMessage;

		if (this.props.cards.some(cards => ['CARD_01', 'CARD_02', 'CARD_03'].includes(cards.product))) {

			return (
				<div className='SettingScreen__Documents'>
					{
						!loader &&
						<BlockingScreen />
					}
				</div>
			);
		}

		return (
			<div className='PhoneScreen'>

				{!successfullChange &&
					<div className='PhoneScreen__Title'>
						{showArrow && <ArrowLeftIcon onClick={this.toBack} className='PhoneScreen__Arrow' />}
						<Title
							title={`${t(M.title)}`}
							className='SettingScreen__Title'
						/>
					</div>
				}

				{!successfullChange && <Tab
					current={current}
				>
					<Tab.Panel>
						<Title
							legend={t(M.information.stepzeroInfo)}
							className='PhoneScreen__Label'

						/>
						{
							user.phone &&
							<Form.Group>
								<Input
									name='phoneCurrent'
									placeholder={t(M.inputs.phone)}
									value={phoneNumber}
									errors={errors.phone}

									disabled
								/>
							</Form.Group>
						}
						<Button
							className='PhoneScreen__Button'
							onClick={this.onClick}
							small
							form
						>
							{t(M.buttons.change)}
						</Button>
					</Tab.Panel>

					<Tab.Panel>
						<Title
							legend={t(M.legend)}
							className='PhoneScreen__Label'
						/>
						<Form.Group>
							<InputSelect
								name='phoneCountry'
								onChange={this.onChange}
								placeholder={t(M.inputs.phoneCountry)}
								value={form.phoneCountry}
								errors={errors.phoneCountry}
								data={phoneCountry}
							/>
						</Form.Group>
						<Form.Group>
							<Input
								name='phone'
								type='number'
								placeholder={t(M.inputs.phone)}
								onChange={this.onChange}
								value={form.phone}
								errors={errors.phone}
							/>
						</Form.Group>
						<Title
							subtitle={t(M.information.changeNumber)}
							className='PhoneScreen__Label'
						/>
						<Form.Group>
							<Button
								className='PhoneScreen__Button'
								onClick={this.onClick}
								loading={loader}
								small
								form
							>
								{t(M.buttons.verify)}
							</Button>
						</Form.Group>
					</Tab.Panel>

					<Tab.Panel>
						<Form.Group>
							<Input
								name='code'
								placeholder={t(M.inputs.code)}
								onChange={this.onChangeSecondStep}
								value={form.code}
								errors={errors.code}
							/>
							<Title subtitle={`${t(M.information.sendCode)} ${phoneNumber}`} />
						</Form.Group>
						<div className='PhoneScreen__Buttons'>
							<Button
								className='PhoneScreen__Button'
								onClick={this.onClick}
								loading={loader}
								small
								form
							>
								{t(M.buttons.verify)}
							</Button>
							<p className='PhoneScreen__ResendInfo'>{t(M.information.resendCode)}</p>
							<ButtonLink
								className='PhoneScreen__Resend'
								onClick={this.onResend}
								disabled={disableResend}
								small
								form
								secondary
							>
								{
									disableResend &&
									<Timer time={this.state.timeResend} />
								}
								{
									!disableResend &&
									t(M.buttons.resend)
								}
							</ButtonLink>
						</div>
					</Tab.Panel>
				</Tab>}
				{successfullChange &&
					<div className='PhoneScreen__Success'>
						<SuccessIcon width='100px' height='100px' />
						<p>{`${t(M.information.successChange)} ${phoneNumber}`}</p>
					</div>
				}
			</div>
		);
	}
}
