import getEnv from "helpers/getEnv";

export const gtmId = 'GTM-NFZ95F3';
export const GAId = 'UA-66844477-1';

export const GTMEvent = {
	signup: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_0'
	},
	regulatoryData: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_1'
	},
	eMailVerification: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_2'
	},
	phoneVerification: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_3'
	},
	congratulationsScreen: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_4'
	},
	secondaryCardIsOrdered: {
		event: 'SECONDARY',
		category: 'SECONDARY',
		action: 'SECONDARY_ORDERED'
	},
	cardIsActivated: {
		event: 'ACTIVATION',
		category: 'ACTIVATION',
		action: 'CARD_ACTIVATED'
	},
	documentsUploaded: {
		event: 'VERIFICATION',
		category: 'VERIFICATION',
		action: 'DOCUMENTS_UPLOADED'
	},
	SPEAPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'SEPA_COMPLETED'
	},
	C2CPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'C2C_COMPLETED'
	},
	internationalPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'IP_COMPLETED'
	},
	voucher: {
		event: 'VOUCHER',
		category: 'VOUCHER',
		action: 'VOUCHER_LOADED'
	},
};

export const firebaseConfig = {
  apiKey: getEnv('AIzaSyCMfv6sDszk9LXLwq00eETd8bMfyyyRPkk', 'AIzaSyC5XoOKdATo3raTS_fzO_EPLanv0i13vD0'),
  authDomain: getEnv('testfirebase-1c8fe.firebaseapp.com', 'weststein-app.firebaseapp.com'),
  databaseURL: getEnv('https://testfirebase-1c8fe.firebaseio.com', 'https://weststein-app.firebaseio.com'),
  projectId: getEnv('testfirebase-1c8fe', 'weststein-app'),
  storageBucket: getEnv('testfirebase-1c8fe.appspot.com', 'weststein-app.appspot.com'),
  messagingSenderId: getEnv('371847385574', '997455524520'),
  appId: getEnv('1:371847385574:web:54874d902e0e8d742a8627', '1:997455524520:web:b5c4d7f7287957b1d4089d'),
  measurementId: getEnv('G-R1XMBMS6C3', 'G-9K4T3KPZMR'),
};
