import React from "react";
import { useEscHandler } from "../../hooks/UseEscModal";
import { injectIntl } from 'react-intl';

import '../ExchangeModal/ExchangeModal.scss'

const ExchangeModal = ({ setModal, submitFormHandler, intl, eur, tron }) => {

	const [closeHandler] = useEscHandler(setModal)

	const buyHandler = (evt) => {
		submitFormHandler(evt);
		closeHandler();
	}

	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">
				<h3 className="exchange-modal__title">{intl.messages["exchange.modal.deduct"]}</h3>

				<p className="exchange-modal__text">{intl.messages["exchange.money"]} {eur} {intl.messages["exchange.buy.deducted"]} {tron} {intl.messages["exchange.create_exchange.usdt"]}</p>

				<button
					className="exchange__button exchange-modal__button"
					type="button"
					onClick={buyHandler}>
					{intl.messages["exchange.modal.buy"]}
				</button>
			</section>
		</div>
	)
};

export default injectIntl(ExchangeModal);