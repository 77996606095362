import moment from 'moment';
import normalize from 'helpers/locales';

const signupPrivate = (form, createOther) => {
	if (!form) return null;

	const convert = {
		...form,
	};

	if (form.name) {
		convert.firstName = form.name;
		delete convert.name;
	}
	if (form.surname) {
		convert.lastName = form.surname;
		delete convert.surname;
	}
	if (form.dateOfBirth) {
		convert.dateOfBirth = moment(form.dateOfBirth, 'DD/MM/YYYY').format('DD-MM-YYYY');
	}
	if (createOther !== true) {
		convert.address = {
			line1: form.address,
			line2: form.address2,
			country: form.country,
			postalCode: form.postalCode,
			city: form.city,
		};
	}
	if (form.agree) {
		convert.termsAndConditions = true;
		convert.privacyPolicy = true;
		delete convert.agree;
	}

	if (form.language) {
		convert.language = normalize.toBackend(form.language);
	}

	return convert;
};



export default {
	signupPrivate,
};
