import React from "react";
import { injectIntl } from 'react-intl';

import './ExchangeModal.scss'

const ExchangeModal = ({ setIsModal, intl }) => {

	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">
				<h3 className="exchange-modal__title">{intl.messages["exchange.modal_first.create_wallet"]}</h3>
				<p className="exchange-modal__text">{intl.messages["exchange.modal_first.cant_sell"]}</p>
				<p className="exchange-modal__text">{intl.messages["exchange.create_exchange.remember_passphrase"]}</p>
				<button
					className="exchange__button exchange-modal__button"
					onClick={() => setIsModal(false)}>
					{intl.messages["exchange.modal_first.good"]}
				</button>
			</section>
		</div>
	)
};

export default injectIntl(ExchangeModal);