import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';


import * as cardActions from 'actions/card';
import * as bankActions from 'actions/bank';
import * as toastActions from 'actions/toast';

import * as clientSelector from 'selectors/client';
import * as cardSelector from 'selectors/card';

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Tab from 'components/Tab';
import Voucher from './Voucher';
import Sepa from './Sepa';
import PFS from './PFS';
// import Swift from "./Swift";

import M from './Load.locale.json';
import './Load.scss';
import { isProduction } from '../../constants';

const mapState = state => ({
	client: clientSelector.getCurrentClient(state),
	card: state.card,
	bank: state.bank,
	currentCard: cardSelector.getById(state, state.card.current)
});

const mapDispatch = dispatch => ({
	actions: {
		client: bindActionCreators(clientSelector, dispatch),
		card: bindActionCreators(cardActions, dispatch),
		bank: bindActionCreators(bankActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	},
});


@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	static columns = [{
		id: 'currency',
		title: M.table.currency,
	}, {
		id: 'correspondentBankName',
		title: M.table.correspondent,
	}, {
		id: 'bic',
		title: M.table.swiftBic,
	}, {
		id: 'accountNumber',
		title: M.table.number,
	}]

	static tabs = [
		{
			id: 'voucher',
			title: M.tabs.voucher,
		},
		{
			id: 'sepa',
			title: M.tabs.sepa,
		},
		{
			id: 'pfs',
			title: { id: 'payments.pfs.section.title' }
		}
		// {
		//	id: "swift",
		//	title: M.tabs.swift,
		// }
	];

	state = {
		loader: false,
		cardid: 0,
		current: 0,
		form: {
			voucher: '',
		}
	};

	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions } = this.props;

		const result = await actions.bank.get();
		return result;
	};

	onTab = (tab, idx) => {
		this.setState({
			current: idx,
		});
		
	};

	onChange = value => this.setState({ form: { voucher: value } })

	render () {
		const { client, intl } = this.props;
		const t = intl.formatMessage;
		const { current } = this.state;
		const access = (client.role === 'OWNER' || client.roleType === 'PRIVATE');
		const tabs = this.constructor.tabs
			.filter(item => item.hasOwnProperty('title'))
			.map(item => {
				const title = t(item.title);

				return {
					...item,
					title,
				};
			});

		if (!access) {
			return <Redirect to="/" />;
		}

		return (
			<Section className="LoadSection">
				<Container>
					<Title title={t(M.header)} />
					<Tab
						header={tabs}
						current={current}
						onChange={this.onTab}
					>
						<Tab.Panel>
							<Voucher />
						</Tab.Panel>
						<Tab.Panel>
							<Sepa />
						</Tab.Panel>
						<Tab.Panel>
							<PFS />
						</Tab.Panel>
						{/* <Tab.Panel> */}
						{/*	<Swift/> */}
						{/* </Tab.Panel> */}
					</Tab>
				</Container>
			</Section>
		);
	}
}
