import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	AUTH_LOGOUT,
	AUTH_EMAIL_SUCCESS,
	SHOW_TWOFACTOR_ALERT,
	OPEN_TWOFACTOR_AUTH,
	STATUS__ACTIVE,
	SUM_SUB_VERIFIVATION_STATUS,
	SHOW_SUMSUB_MODAL,
	SHOW_UI_ALERT


} from 'constants';
import { success, error } from 'actions/toast';
import renamer from 'helpers/renamer';
import API from 'services/api';
import normalize from 'helpers/locales';
import M from './auth.locale.json';

const dispatchLoginAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = normalize.fromBackend(user.language);
	const messages = await API.language.get(locale);
	
	dispatch({
		type: AUTH_LOGIN_SUCCESS,
		payload: {
			user,
			list: list.data.response,
			language: {
				locale,
				messages: messages.data
			}
		}
	});

	if (!user.twoFactor && user.phoneVerified && !user.twoFactorNotification) { 
		dispatch({ type: SHOW_TWOFACTOR_ALERT });
	}
	if (user.id) {
		await subSubVerification(user.id)
	}

	return user;
};

const dispatchSignupAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = normalize.fromBackend(user.language)
	const messages = await API.language.get(locale);

	dispatch({
		type: AUTH_SIGNUP_SUCCESS,
		payload: {
			user,
			list: list.data.response,
			language: {
				locale,
				messages: messages.data
			}
		}
	});
	return user;
};

export const login = form => async dispatch => {
	try {
		const {
			data: { response }
		} = await API.auth.login(form);

		if (response.twoFactor) {
			dispatch({ type: OPEN_TWOFACTOR_AUTH, payload: response });
			return response;
		}

		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const getCodeTwoFactor = (form) => async dispatch => {
	try {
		const result = await API.twoFactor.generateCode(form);
		success(dispatch)({ title: M.success.get.title });
		return result.data.response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
}

export const sendCodeTwoFactor = form => async dispatch => {
	try {
		const { data: { response } } = await API.twoFactor.sendCode(form);

		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const loginCreditCard = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.loginCreditCard(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const activate = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.activate(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const signup = {
	private: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.private(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) {
			return error;
		}
	},
	business: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.business(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) {
			return error;
		}
	}
};

export const logout = () => async dispatch => {
	try {
		const { data: { response } } = await API.auth.logout();
		dispatch({ type: AUTH_LOGOUT });
		return response;
	} catch (error) {
		return error;
	}
};

export const reset = form => async () => {
	try {
		const { data: { response } } = await API.auth.reset(form);
		return response;
	} catch (error) {
		return error;
	}
};

export const password = (form, token) => async dispatch => {
	try {
		const result = await API.auth.password(form, token);
		success(dispatch)({ title: M.success.password.title });
		return result.data.response;
	} catch (error) {
		return error;
	}
};

export const email = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.email(form);
		dispatch({ type: AUTH_EMAIL_SUCCESS, payload: response });
		return response;
	} catch (error) {
		if (error.errors) error.errors = renamer.from.email(error.errors);
		return error;
	}
};

export const getLastRequest = (form) => async () => {
	try {
		const { data: { response } } = await API.twoFactor.getLastRequestLogin(form);
		return response;
	} catch (error) {
		return error;
	}
};

export const accountReviewSuccess = () => async(dispatch) => {
	try {
		const { data } = await API.user.accountReviewSuccess();
		dispatch({type: STATUS__ACTIVE});
		return data;
	} catch(error) {
		return error;
	}
}

export const getSumSubVerification = (id) => async() => {
	try {
		const {data} =  await API.user.SumSubVerification(id)
		console.log(data)
		return data;

	}

	catch(error) {
		return error
	}
}

export const subSubVerification = (id) => async(dispatch) => {
	await API.user.getSumSubStatus(id).then(async ({data}) => {
		if (data.type === 'OK') {
			dispatch({ type: SUM_SUB_VERIFIVATION_STATUS, payload: data.data });
			if (data.data === 'no_verify' || data.data === 'reject') dispatch({type: SHOW_SUMSUB_MODAL, payload: true})
			if (data.data === 'in_progress') dispatch({type: SHOW_UI_ALERT, payload: {
				title: 'Wait a little more',
				text: 'Your account is being verified',
			}})
		} else {
			 await API.user.createUser(id).then(async({data}) => {
				if (data.type === 'OK') {
					await API.user.getSumSubStatus(id).then(async ({data}) => {
						dispatch({ type: SUM_SUB_VERIFIVATION_STATUS, payload: data.data });
						if (data.data === 'no_verify' || data.data === 'reject') dispatch({type: SHOW_SUMSUB_MODAL, payload: true})
					})
				} else {
					console.log(err)

				}

			})
		}
	})
}