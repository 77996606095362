/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { API } from '../../services/api/exchange-api';
import { InfoUrl, Method, OperationType, operationTypes, TradeType } from '../../constants/exchange-const';
import { injectIntl } from 'react-intl';

import './OperationTypes.scss';

import { useSelector } from 'react-redux';


const OperationTypes = ({
	setOperationType,
	operationType,
	setTradeInfo,
	userInfo,
	setEur,
	setTron,
	directionRef,
	intl }) => {

	const [types, setTypes] = useState();
	const isDisabled = label => label === `change`;

	const arrCard = useSelector(( {card} ) => card.items.filter(i => i.id == card.current));
	const cardholderId = arrCard[0].id

	const getTradeType = type => {
		switch (type) {
			case OperationType.CRYPTO_TO_FIAT:
				directionRef.current = TradeType.EUR_USDT;
				return TradeType.EUR_USDT;
			case OperationType.FIAT_TO_CRYPTO:
				directionRef.current = TradeType.USDT_EUR;
				return TradeType.USDT_EUR;
			default:
				return;
		}
	};

	const operationTypeHandler = value => {
		setOperationType(value);
		setEur(``);
		setTron(``);

		if (value !== OperationType.CRYPTO_TO_CRYPTO) {
			API({
				method: Method.GET,
				url: `/${cardholderId}` + InfoUrl.TRADE_INFO,
				headers: {
					direction: getTradeType(value)
				}
			})
				.then(({ data }) => setTradeInfo(data.response.data));
		}
	};


	useEffect(() => {

		let intlTypes = [];
		for (const operation of operationTypes) {
			const { value, label, id } = operation;
			let newLabel = intl.messages[`exchange.type.${label}`]

			intlTypes.push({ value, id, label: newLabel })
		}
		if (userInfo.wallet) {
			setTypes(intlTypes);
		} else {
			setTypes([intlTypes[0]]);
		}

	}, []);

	return (
		<ul className="operation-types">
			{
				types &&
				types.map(({ value, label }, idx) => {
					let checked = value === operationType;

					console.log(`label `,label);

					return (
						<li
							key={idx}
							className={`operation-types__list-item `}
						>
							<label className={`operation-types__label-checkbox 
              ${checked ? `checked-label` : ``} 
              ${!isDisabled(label) ? `` : `operation-types__label-checkbox--disabled`}`}
							>
								<span
									className={checked ? `checked-label` : ``}
								>{label}</span>
								<input
									className="form__checkbox"
									onChange={() => operationTypeHandler(value)}
									type="radio"
									name="operation"
									value={value}
									checked={checked}
									disabled={isDisabled(label)}
								/>
							</label>
						</li>
					);
				})
			}
		</ul>

	);
};

export default injectIntl(OperationTypes);
