import React from "react";
import { useEscHandler } from "../../hooks/UseEscModal";
import { injectIntl } from 'react-intl';

import '../ExchangeModal/ExchangeModal.scss'

const SellModal = ({ setModal, submitFormHandler, intl, eur, tron }) => {
	const [closeHandler] = useEscHandler(setModal)

	const sellHandler = (evt) => {
		submitFormHandler(evt);
		closeHandler();
	}

	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">

				<p className="exchange-modal__text">{intl.messages["exchange.sell.modal"]} {tron} {intl.messages["exchange.create_exchange.usdt"]} {intl.messages["exchange.sell.modal.get"]} {eur} {intl.messages["exchange.create_exchange.eur"]} {intl.messages["exchange.sell.modal.weststein"]}</p>

				<button
					className="exchange__button exchange-modal__button"
					type="button"
					onClick={sellHandler}>
					{intl.messages["exchange.modal.awesome"]}
				</button>
			</section>
		</div>
	)
};

export default injectIntl(SellModal);