import React from "react";
import { injectIntl } from 'react-intl';

const CustomModal = ({ setIsModal, modalText, intl }) => {
	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">
				<p className="exchange-modal__text">{modalText}</p>
				<button
					style={{ backgroundColor: `red` }}
					className="exchange__button exchange-modal__button"
					onClick={() => setIsModal(false)}
				>
					{intl.messages["exchange.modal_first.good"]}
				</button>
			</section>
		</div>
	);
};

export default injectIntl(CustomModal);
