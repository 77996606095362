import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { getById } from 'selectors/card';

import intlTypes from '../../../../types/intl';
import { getIsUserVerified } from '../../../../selectors/user';
import VerifyCardModal from '../../../../sections/Dashboard/VerifyCardModal';
import historyTypes from '../../../../types/history';

const DOCUMENT_URL = '/settings/document';

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id),
	isUserVerified: getIsUserVerified(state)
});

@withRouter
@injectIntl
@connect(mapState)
class GetPlastic extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		isUserVerified: PropTypes.bool,
		history: historyTypes.isRequired,
		checkBalance: PropTypes.func.isRequired
	};

	state = {
		isVerificationModalVisible: false,
		isLoading: false,
	};

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onGetPlasticCardClick = () => {
		const {
			isUserVerified,
		} = this.props;

		if (!isUserVerified) {
			
			return this.setState({ isVerificationModalVisible: true });
		}
     
		this.setState({ isLoading: true });
		this.props.checkBalance();
	};

	onVerificationSkip = () =>
		this.setState({ isVerificationModalVisible: false });

	onVerifyCard = () => this.props.history.push(DOCUMENT_URL);

	mounted = false;

	render () {
		const { isUserVerified } = this.props;
		const { isVerificationModalVisible, isLoading } = this.state;
		const t = this.props.intl.formatMessage;

		return (
			<div>
				<React.Fragment>
					{isVerificationModalVisible && !isUserVerified && (
						<VerifyCardModal
							t={t}
							subtitle=""
							onSkip={this.onVerificationSkip}
							onVerify={this.onVerifyCard}
						/>
					)}
				</React.Fragment>
				<p>{t({ id: 'card.get.plastic.text' })}</p>
				<Button onClick={this.onGetPlasticCardClick} loading={isLoading}>
					<FormattedMessage id="card.get.plastic.button" />
				</Button>
				
			</div>
		);
	}
}

export default GetPlastic;
