import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import * as uiActions from 'actions/ui';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import Icon from 'components/Icon';

import './UiAlert.scss';

const mapState = state => ({
	alert: state.ui.alert,
});
const mapDispatch = dispatch => ({
	actions: {
		ui: bindActionCreators(uiActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class UiAlert extends PureComponent {
	onSkip = () => {
		const { actions } = this.props;

		actions.ui.hideAlert();
	};

	render () {
		const { alert: { showUiAlert, title, subtitle, text }, intl } = this.props;
		const t = intl.formatMessage;
		if (!showUiAlert) {
			return null;
		}

		return (
			<Modal>
				<Section>
					<Container medium>
						<Wrap className="ui-modal">
							<Icon
								className="ui-modal__close" 
								onClick={this.onSkip} 
								name="close-small"
							/>
							<Title
								title={t({'id':title})}
								subtitle={subtitle}
								small
							/>
							{text && (
								<p className="ui-modal__text">
									<FormattedMessage id={text} />
								</p>
							)}
							<div className='btn'>
							    <Button
									onClick={this.onSkip}
									small
									className="ui-modal__button"
									>
									<FormattedMessage id="ui.alert.button.close" />
								</Button>
							</div>		
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}