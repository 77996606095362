import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';

const getCommonPrimaryInfoURL = (cid, bid) =>
	`${bid ? `business/${bid}` : `private`}/card-info/${cid}`;

const getCommonPrimaryRequestURL = (cid, bid) =>
	`${bid ? `business/${bid}` : `private`}/card-request/${cid}`;

export default {
	get (cid, bid) {
		return fetcher.get(getCommonPrimaryInfoURL(cid, bid));
	},
	virtualCards: {
		number (cid, bid) {
			return fetcher.get(`${getCommonPrimaryInfoURL(cid, bid)}/number`);
		},
		expirationDate (cid, bid, zipCode) {
			return fetcher.put(`${getCommonPrimaryInfoURL(cid, bid)}/exp-date`, {
				zipCode
			});
		},
		// CVV code is sent to user's phone via SMS
		cvv (cid, bid, zipCode) {
			return fetcher.put(`${getCommonPrimaryInfoURL(cid, bid)}/cvv`, {
				zipCode
			});
		},
		pin (cid, bid, zipCode) {
			return fetcher.put(`${getCommonPrimaryInfoURL(cid, bid)}/pin`, {
				zipCode
			});
		},
		updateVirtualToPlastic (cid, bid) {
			return fetcher.put(`${getCommonPrimaryRequestURL(cid, bid)}/plastic`);
		}
	},
	pin (cid, bid) {
		return fetcher.post(`${getCommonPrimaryInfoURL(cid, bid)}/pin-reminder`);
	},
	activate (cnumber, cid, bid) {
		return fetcher.post(`${getCommonPrimaryInfoURL(cid, bid)}/activate/${cnumber}`);
	},
	block (cid, bid) {
		return fetcher.post(`${getCommonPrimaryInfoURL(cid, bid)}/block`);
	},
	unblock (cid, bid) {
		return fetcher.post(`${getCommonPrimaryInfoURL(cid, bid)}/un-block`);
	},
	secondary (cid, data) {
		return fetcher.post(`private/card-request/${cid}/secondary`, data);
	},
	secondaryOther (cid, form) {
		const createOther = true;
		const convert = renamer.to.auth.signupPrivate(form, createOther);
		return fetcher.post(`private/card-request/${cid}/secondary/other`, convert);
	},
	update (cardId, data) {
		return fetcher.put(`private/card-info/${cardId}`, data);
	},
	reissue (cardId, data) {
		return fetcher.post(`private/card-reissue/${cardId}`, data);
	},
};
