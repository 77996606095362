import React from 'react';
import { injectIntl } from 'react-intl';

import './UserInfo.scss';

const UserInfo = ({ userInfo, intl }) => {
	const {
		wallet,
		balance_usdt,
		balance_tron,
	} = userInfo;

	return (
		<section className="user-info">
			<div className="user-info__currencies">
				<div className="user-info__currency-block">
					<span>{intl.messages['exchange.user.wallet']}</span>
					<span>{wallet}</span>
				</div>
				<div className="user-info__currency-block">
					<span>{intl.messages['exchange.user.balance_usdt']}</span>
					<span>{balance_usdt}</span>
				</div>
				<div className="user-info__currency-block">
					<span>{intl.messages['exchange.user.balance_tron']}</span>
					<span>{balance_tron}</span>
				</div>
			</div>
		</section>
	);
};

export default injectIntl(UserInfo);
