import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import Form from 'components/Form';
import Input from 'components/Input';
import InputError from 'components/InputError';
import InputRadio from 'components/InputRadio';
import Button from 'components/Button';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';


import * as cardSelector from 'selectors/card';
import * as userSelector from 'selectors/user';

import * as pfsPaymentActions from 'actions/pfs';
import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import userType from 'types/user';
import helpers from 'helpers';
import validationSchema from 'schemas/pfs';
import { CARD_TO_CARD_LIMITATION } from 'constants/common';

import WarningModal from './PfsWarningModal';
import BlockingScreen from '../../screens/Blocking';

import './PFS.scss';

const mapState = state => ({
	currentCard: cardSelector.getById(state, state.card.current),
	currentUser: userSelector.getUser(state),
});

const mapDispatch = dispatch => ({
	actions: bindActionCreators(pfsPaymentActions, dispatch),
});

@injectIntl
@connect(mapState, mapDispatch)
export default class PFS extends Component {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			performPayment: PropTypes.func.isRequired,
			fetchLinkedCards: PropTypes.func.isRequired,
			removeLinkedCard: PropTypes.func.isRequired,
		}),
		currentCard: currentCardTypes,
		currentUser: userType,
	};

	constructor (props) {
		super(props);

		this.onLinkedChange = this.onLinkedChange.bind(this);
		this.setCloseModal = this.setCloseModal.bind(this);
	}

	state = {
		amount: '',
		errors: [],
		selectErrors: [],
		isWarning: false,
		isLoading: false,
	};

	componentDidMount () {
		this.props.actions.fetchLinkedCards();
	}

	componentDidUpdate () {
		const { currentUser: { linkedCards } } = this.props;
		const { linkedCardId } = this.state;
		const isUpdateNeeded = linkedCardId !== null 
			&& linkedCards 
			&& linkedCards.length 
			&& linkedCards.find(({ id }) => id === linkedCardId) === undefined;

		if (isUpdateNeeded) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ linkedCardId:  linkedCards[0].id });
		}
	}

	onChange = value => {

		if (parseFloat(value) < 0) {
			return;
		}
		
		const errors = helpers.validator.all({ amount: value }, validationSchema.pfs);

		if (errors) {
			this.setState({ errors: errors.amount });
		} else {
			this.setState({ errors: [] });
		}

		this.setState({ amount: value });
	};

	onLinkedChange = value => {
		this.setState({ linkedCardId: value, selectErrors: [] });
	}

	onLinkedDelete = value => {
		this.setState({ isLoading: true });
		this.props.actions.removeLinkedCard(value);
		this.setState({ isLoading: false, linkedCardId: undefined });
	}

	onConfirmSubmit = async () => {
		const { amount, linkedCardId } = this.state;
		this.setState({ isLoading: true });
		await this.props.actions.performPayment({ amount, tokenId: linkedCardId });
		setTimeout(()=> {
			this.setState({ isLoading: false, isWarning: false });
		}, 3500);
	
	}

	onSubmit = e => {
		e.preventDefault();
		const { errors, amount, linkedCardId, selectErrors } = this.state;
		const { linkedCards } = this.props.currentUser;

		if (errors.length || selectErrors.length) {
			return;
		}

		const validationErrors = helpers.validator.all({ amount }, validationSchema.pfs);
		const linkedSelectErrors = linkedCardId === undefined && linkedCards && linkedCards.length
			? [{ id: 'Choose a card' }]
			: null;

		if (linkedSelectErrors || validationErrors) {
			this.setState({ selectErrors: linkedSelectErrors || [] });
			this.setState({ errors: validationErrors.amount || [] });

			return;
		}
		
		if (!linkedCardId) {
			this.setState({ isWarning: true, linkedCardId: null });

			return;
		} 
		
		this.onConfirmSubmit();
	};

	setCloseModal = () => {
		if (this.state.isLoading) {	
			return;
		} 

		this.setState({ isWarning: false });
	}

	checkIsCardAvailable = () => {
		const { currentCard, currentUser } = this.props;

		if (helpers.checker.isSU(currentUser) && helpers.checker.isCardOpen(currentCard)) {
			return true;
		}

		return (
			helpers.checker.isCardInProductLimitation(CARD_TO_CARD_LIMITATION, currentCard) 
			&& helpers.checker.isCardOpen(currentCard)
		);
	};

	render () {
		const { intl, currentUser: { linkedCards } } = this.props;
		const t = intl.formatMessage;
		const { errors, amount, isLoading, linkedCardId, isWarning, selectErrors } = this.state;
		const hasLinkedCards = linkedCards && !!linkedCards.length;
		if (this.checkIsCardAvailable()) {
			return (
				<div className="payment-section-pfs">
					<p className="payment-section-pfs__description">
						{t({ id: 'payments.pfs.section.description' })}
					</p>
					<Form onSubmit={this.onSubmit}>
						{hasLinkedCards && (
							<Form.Group className="payment-section-pfs__linked-group">
								<div className="payment-section-pfs__linked-wrapper"> 
									{linkedCards && linkedCards.map((card, i) => (
										<div className="payment-section-pfs__linked-item" key={card.id}>
											<InputRadio
												id={`linked-id-${i}`}
												name={`linked-${i}`}
												value={card.id}
												onChange={this.onLinkedChange}
												checked={linkedCardId === card.id}
											>
												{`${t({ id:'payments.pfs.section.card.number' })} ${card.cardNumber} `}
											</InputRadio>
											<span
												tabIndex={0}
												role="button"
												onClick={!card.days ? () => this.onLinkedDelete(card.id) : undefined}
												className={cn(
													'payment-section-pfs__linked-delete',
													{ 'linked-delete_disabled': !!card.days }
												)}
											>
												{t({ id:'payments.pfs.section.card.unlink' })}
											</span>
											{!!card.days && <span className="payment-section-pfs__delete-blocked">
												<FormattedMessage
													id="pages.payments.pfs.daysleft"
													values={{
														days: card.days,
													}}
													
												/>
											</span>}

										</div>
									))}
									{linkedCards && linkedCards.length === 1 && (
										<InputRadio
											id="linked-id-new"
											name="linked-new"
											value={null}
											onChange={this.onLinkedChange}
											checked={linkedCardId === null}
										>
											{t({ id:'payments.pfs.section.newcard.text' })}
										</InputRadio>
									)}
								</div>
							</Form.Group>
						)}
						<Input
							type="number"
							min={0}
							className="payment-section-pfs__input"
							name="amount"
							placeholder={t({ id: 'payments.pfs.section.form.price' })}
							onChange={this.onChange}
							value={amount}
							errors={errors}
						/>
						<InputError errors={selectErrors} />
						<p className="payment-section-pfs__description">
							{t({ id: 'payments.pfs.section.fee' })}
							<span>{t({ id:'payments.pfs.section.description.footnote' })}</span>
							<span>{t({ id:'payments.pfs.section.card.number.footnote' })}</span>
							
						</p>

						<Button
							className="payment-section-pfs__button"
							form
							small
							loading={isLoading}
						>
							{t({ id: 'payments.pfs.section.form.button' })}
						</Button>
					</Form>
					{isWarning && <WarningModal 
						setCloseModal={this.setCloseModal} 
						onSubmit={this.onConfirmSubmit} 
						isLoading={isLoading}
					/>}
				</div>
			);
		}
		return (
			<Section className="LoadSection">
				<Container>
					<Wrap>
						<BlockingScreen />
					</Wrap>
				</Container>
			</Section>
		);
	}
}
