
import React from "react";
import { useEscHandler } from "../../hooks/UseEscModal";

const SuccessExchangeModal = ({ message, setModal }) => {
	const [closeHandler] = useEscHandler(setModal)


	return (
		<div className="exchange-modal">
			<section className="exchange-modal__block">
				<h3 style={{ color: `${message === `INCORRECT PASSPHRASE` ? `red` : `#000`}` }} className="exchange-modal__title" href ={`https://tronscan.org/#/transaction/${message}`} > {message}</h3>
				<button
					className="exchange__button exchange-modal__button"
					type="button"
					onClick={() => closeHandler()}>
					OK
				</button>
			</section>
		</div>
	)
}

export default SuccessExchangeModal;